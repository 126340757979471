<div
	class="change-log-container"
	[class.change-log-container_only-latest]="isOnlyLatestChanges"
	>

	<!-- ФОН -->
	<div *ngIf="!isOnlyLatestChanges" class="clc-overlay msl-dialog-overlay" (click)="onClickCloseHandler(true)"></div>

	<label
		class="clc-header-only-last"
		*ngIf="isOnlyLatestChanges"
		>
		{{ 'system-info.only_latest_changes' | translate }}
		<span>{{ changeLogService.latestVersion }}</span>
	</label>

	<div class="clc-content-wrp" [class.clc-content-wrp_only-latest]="isOnlyLatestChanges">

		<!-- ПАНЕЛЬ -->
		<div class="clc-content msl-dialog-container"
			 [class.clc-content_only-latest]="isOnlyLatestChanges"
		>
			<!-- КНОПКА "ЗАКРЫТЬ ОКНО" -->
			<app-close-button
				class="close-button"
				*ngIf="!isOnlyLatestChanges"
				(action)="onClickCloseHandler()"
			>
			</app-close-button>

			<!-- КОНТЕНТ -->
			<div class="changes-scroll webalt-scroll" [class.changes-scroll_only-latest]="isOnlyLatestChanges">
				<div
					class="cl-items"
					[class.cl-items_only-latest]="isOnlyLatestChanges"
					[innerHTML]="isOnlyLatestChanges ? changeLogService.parsedLatestChangelog : changeLogService.parsedChangelog"
				>
				</div>

				<div *ngIf="!isOnlyLatestChanges" class="settings">
					<h2 class="settings__title">{{ 'system-info.settings' | translate }}</h2>
					<div class="camera-settings">
						<!-- Чекбокс "Показывать камеру" -->
						<div class="camera-settings__item">
							<input
								class="camera-settings__checkbox"
								type="checkbox"
								id="show_camera"
								[checked]="appStoreService.showCameraComponent$$.value"
								(change)="onShowCameraChange()"
							>
							<label for="show_camera">{{ 'system-info.show_camera' | translate }}</label>
						</div>
					</div>
					<div class="ticket-mode">
						<div class="ticket-mode__title">{{ 'system-info.trs_mode' | translate }}:</div>
						<div class="ticket-mode__item">
							<input class="ticket-mode__option"
								   type="radio"
								   name="use_trs_mode"
								   id="use_trs_mode_no"
								   value="0"
								   [attr.checked]="trsMode === 0 || undefined"
								   (change)="onTRSModeChange($event)">
							<label for="use_trs_mode_no">{{ 'system-info.trs_off' | translate }}</label>
						</div>
						<div class="ticket-mode__item">
							<input class="ticket-mode__option"
								   type="radio"
								   name="use_trs_mode"
								   id="use_trs_rows_mode_yes"
								   value="1"
								   [attr.checked]="trsMode === 1 || undefined"
								   (change)="onTRSModeChange($event)"
							>
							<label for="use_trs_rows_mode_yes">{{ 'system-info.trs_on_rows' | translate }}</label>
						</div>
						<div class="ticket-mode__item">
							<input class="ticket-mode__option"
								   type="radio"
								   name="use_trs_mode"
								   id="use_trs_full_mode"
								   value="2"
								   [attr.checked]="trsMode === 2 || undefined"
								   (change)="onTRSModeChange($event)"
							>
							<label for="use_trs_full_mode">{{ 'system-info.trs_on_full' | translate }}</label>
						</div>
						<!--					<div class="ticket-mode__item">
												<input class="ticket-mode__option"
													   type="radio"
													   name="use_trs_mode"
													   id="use_browser"
													   value="3"
													   [attr.checked]="trsMode === 3 || undefined"
													   (change)="onTRSModeChange($event)"
												>
												<label for="use_browser">{{ 'system-info.browser' | translate }}</label>
											</div>-->
					</div>
				</div>

			</div>
		</div>
	</div>

	<!-- КНОПКА "ЗАКРЫТЬ ОКНО" -->
	<button *ngIf="isOnlyLatestChanges"
		type="button"
		class="button button_theme_green modal-button modal-button_close-changelog"
		data-cy="changelog-close"
		(click)="onClickCloseHandler()"
		>
		{{ 'dialog.dialog_button_continue' | translate }}
	</button>

</div>
