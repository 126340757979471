import { IResponse } from '@app/core/net/http/api/types';
import { convertDateToISOFormat } from '@app/util/utils';
import { OPS_LIST } from './operations-list';
import { SESS_DATA } from './session-data';
import { BML_LIST } from './tml-bml-list';
import {DRAWS_FOR_GAME_ZABAVA} from "./zabava-draws";
import {DRAWS_FOR_GAME_MEGALOT} from "./megalot-draws";
import {DRAWS_FOR_GAME_GONKA} from "./gonka-draws";
import {DRAWS_FOR_GAME_KARE} from "./kare-draws";
import {DRAWS_FOR_GAME_SPORTPROGNOZ} from "./sportprognoz-draws";

/**
 * Mock-данные для списка операций в компоненте суммарного чека.
 */
export const OPERATIONS_LIST = OPS_LIST;

/**
 * Mock-данные сессии пользователя.
 */
export const SESSION_DATA = SESS_DATA;

/**
 * Mock-список билетов для бумажных моментальных лотерей.
 */
export const TML_BML_LIST = BML_LIST;

/**
 * Текущее время в ISO-формате.
 */
const time_stamp = convertDateToISOFormat((new Date()).toISOString());

/**
 * Mock-идентификатор сессии.
 */
export const sid = '3b8b3032-87ed-444f-8290-9fd07abc9c43';

/**
 * Mock-идентификатор пользователя.
 */
export const user_id = 10568;

/**
 * Текущая дата в ISO-формате без времени.
 */
const currDate = (new Date()).toISOString()
	.split('T')[0];

/**
 * Основной объект с mock-данными.
 */
export const MOCK_DATA = {
	ASAltWeb: [
		{
			request: {
				ACTION: 'BOGetClientList'
			},
			response: {
				"client_list": [
					{
						"client_id": 106,
						"login": "0672184075"
					},
					{
						"client_id": 109,
						"login": "0675050677"
					},
					{
						"client_id": 110,
						"login": "0675050678"
					},
					{
						"client_id": 163,
						"login": "0503315011"
					},
					{
						"client_id": 1559,
						"login": "0687775963"
					},
					{
						"client_id": 1591,
						"login": "0672184204"
					},
					{
						"client_id": 1816,
						"login": "0672184149"
					}
				],
				"client_trans_id": "421418564098",
				"err_code": 0,
				"err_descr": "Операція виконана успішно.",
				sid,
				time_stamp,
				user_id
			}
		},
		{
			request: {
				ACTION: 'ResendAuth2'
			},
			response: {
				"client_trans_id": "421419379971",
				"err_code": 0,
				"err_descr": "Операція виконана успішно.",
				time_stamp
			}
		},
		{
			request: {
				ACTION: 'GetBonusBalance',
				CARD_NUM: '11122233'
			},
			response: {
				client_id: 123,
				client_trans_id: '1234567',
				err_code: 0,
				err_descr: '',
				sid,
				user_id,
				card_num: '11122233',
				total_bonus_sum: 1000
			}
		},
		{
			request: {
				ACTION: 'SendPinCode',
				PHONE: '+380681234567'
			},
			response: {
				client_id: 123,
				client_trans_id: '1234567',
				err_code: 0,
				err_descr: '',
				sid,
				user_id,
				card_num: '11122233',
				total_bonus_sum: 1000
			}
		},
		{
			request: {
				ACTION: 'VerifyPhone',
				PHONE: '+380681234567',
				PIN: '1234'
			},
			response: {
				client_id: 123,
				client_trans_id: '1234567',
				err_code: 0,
				err_descr: '',
				sid,
				user_id,
				card_num: '11122233',
				total_bonus_sum: 1000
			}
		},

		{
			request: {
				ACTION: 'GetUserByPhone',
				PHONE: '+380681234567',
				PIN: '1234'
			},
			response: {
				client_id: 123,
				client_trans_id: '1234567',
				err_code: 0,
				err_descr: '',
				sid,
				user_id,
				card_num: '11122233'
			}
		},

		{
			request: {
				ACTION: 'AuthPhone',
				PHONE: '+380681234567'
			},
			response: {
				client_id: 123,
				client_trans_id: '1234567',
				err_code: 0,
				err_descr: '',
				sid,
				user_id,
				card_num: '11122233',
				total_bonus_sum: 1000
			}
		},

	],
	ClientAuthSrvDev: [
		{
			request: {
				ACTION: 'ClientAuth'
			},
			response: {
				client_sid: 'FORESEE_64GB_SSD_I37392J01351268:ed:a4:22:f2:5b',
				client_trans_id: '406792513025',
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				time_stamp
			}
		},
		{
			request: {
				ACTION: 'BOAuthenticate'
			},
			response: {
				pos_list: [
				// 	{
				// 	pos: {
				// 		client_id: 40004,
				// 		access_level: 0,
				// 		pos_code: 'kc2138',
				// 		pos_name: 'ТОВ АйЛОТ, на Симеренка',
				// 		pos_addr: 'Київ, вул. Симиренко, 36, оф. 30'
				// 	}
				// },
				{
					pos: {
						client_id: 40005,
						access_level: 0,
						pos_code: 'kc2138',
						pos_name: 'ТОВ АйЛОТ, на Симеренка',
						pos_addr: 'Київ, вул. Симиренко, 36, оф. 30'
					}
				}],
				access_level: 0,
				client_trans_id: '1589033369270',
				err_code: 0,
				err_descr: 'Операція виконана успішно. ',
				lang: 'ua',
				operations: [{
					o: {
						descr: '',
						id: '1000',
						name: 'BOGetAccountInfo'
					}
				},           {
					o: {
						descr: '',
						id: '1005',
						name: 'BOChangePassword'
					}
				}],
				sid,
				time_stamp,
				user_id
			}
		}
	],
	GSAltWeb: [
		{
			request: {
				ACTION: 'BOAuthenticate'
			},
			response: {
				pos_list: [
					{
						pos: {
							client_id: 40004,
							access_level: 0,
							pos_code: 'kc2138',
							pos_name: 'ТОВ АйЛОТ, на Симеренка',
							pos_addr: 'Київ, вул. Симиренко, 36, оф. 30'
						}
					},
					{
						pos: {
							client_id: 40005,
							access_level: 0,
							pos_code: 'kc2138',
							pos_name: 'ТОВ АйЛОТ, на Симеренка',
							pos_addr: 'Київ, вул. Симиренко, 36, оф. 30'
						}
					}
				],
				access_level: 32566,
				auth2: 'sms',
				client_trans_id: '15890518164693',
				err_code: 0,
				err_descr: 'Операція виконана успішно. ',
				lang: 'ua',
				operations: [
					{
						o: {
							descr: '',
							id: '1000',
							name: 'BOGetAccountInfo'
						}
					},
					{
						o: {
							descr: '',
							id: '1005',
							name: 'BOChangePassword'
						}
					}
				],
				sid,
				time_stamp,
				user_id: 10568
			}
		},
		{
			request: {
				ACTION: 'BOGetSID'
			},
			response: {
				auth2: 'sms',
				client_trans_id: '15890518164693',
				err_code: 0,
				err_descr: 'Операція виконана успішно. ',
				lang: 'ua',
				sid,
				time_stamp,
				user_id: 10568
			}
		},
		{
			request: {
				ACTION: 'BOGetClientList'
			},
			response: {
				client_id: '40004',
				user_id: 10568,
				sid,
				client_list: [
					{client_id: 12345, login: '+38(097)326-12-88'},
					{client_id: 54321, login: '+38(096)112-34-56'},
					{client_id: 11123, login: '+38(050)544-53-05'},
				]
			}
		},
		{
			request: {
				ACTION: 'ResendAuth2'
			},
			response: {
				client_id: '40004',
				client_trans_id: '15890518164693',
				err_code: 0,
				err_descr: 'Операція виконана успішно. '
			}
		},
		{
			request: {
				ACTION: 'BOAuth2'
			},
			response: {
				client_trans_id: '406797315842',
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				pos_list: [
					{
						pos: {
							access_level: 0,
							client_id: 40004,
							pos_addr: 'Київ, вул. Симиренко, 36, оф. 30',
							pos_code: 'kc2138',
							pos_name: 'ТОВ АйЛОТ, на Симеренка'
						}
					},
					{
						pos: {
							access_level: 0,
							client_id: 40005,
							pos_addr: 'Київ, вул. Симиренко, 36, оф. 30',
							pos_code: 'kc2138',
							pos_name: 'ТОВ АйЛОТ, на Симеренка'
						}
					}
				],
				time_stamp,
			}
		},
		{
			request: {
				ACTION: 'GetReportData',
			},
			response: {
				client_id: 51508,
				client_trans_id: '407126295566',
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				// report: '999|Содержимое отчета можно получить только с реального бек-енда (но он за NATом)',
				// report: '0|77009|25.09.2020 12:51\n\n22|КОЛЕСО УДАЧІ\n22|Серія 01                   8 шт.\n22|0661-000104-002..003|2\n22|0661-000104-005..007|3\n22|0661-000104-029..029|1\n22|0661-000104-031..031|1\n22|0661-000104-043..043|1\n\n22|КОЛЕСО УДАЧІ\n22|Серія 01                  44 шт.\n22|0661-000105-002..005|4\n22|0661-000105-011..050|40\n\n22|Щаслива 13\n22|Серія 11                 100 шт.\n22|0637-000016-001..100|100\n\n22|Щаслива 13\n22|Серія 11                 100 шт.\n22|0637-000017-001..100|100\n\n22|Щаслива 13\n22|Серія 11                  93 шт.\n22|0637-000018-002..002|1\n22|0637-000018-008..008|1\n22|0637-000018-010..100|91\n\n22|Щаслива 13\n22|Серія 11                 100 шт.\n22|0637-000019-001..100|100\n\n22|Щаслива 13\n22|Серія 11                 100 шт.\n22|0637-000030-001..100|100\n23|545',
				// report: '9000|11.08|10.09|10/09/2021 08:56:29|51510|wa_77010          |Хоме********евич\n9028|Гонка на     |гроші        |      2|        0\n9021|Каре         |      5|        0\n9021|МЕГАЛОТ      |  17710|        0\n9021|Тіп          |      2|        0\n9021|Лото-Забава  |   1220|        0\n9021|Парочка      |    140|        0\n9028|Багаті та    |відомі       |     22|        0\n9021|Морський бій |    170|        0\n9028|Товстий      |гаманець     |    145|        0\n9023|  19416|        0\n9001|\n9099|До сплати в касу        19416.00\n',
				report: '3|604|\n' +
					'20|12345|\n' +
					'22|10/09/2030 08:56:29|\n' +
					'23|Пример программы в Спортпрогнозе|\n' +
					'21|1|Барселона|Шахтер-Донецк|Матч|07.07.2027|17:00|100|1.3|1.4\n' +
					'21|2|Динамо-Киев|Шахтер-Донецк|Ололо трололо|07.07.2027|17:00|1.2|1.3|1.4\n' +
					'21|3|Динамо-Киев|Шахтер-Донецк|Матч|07.07.2027|17:00|1.2|1.3|1.4\n' +
					'21|4|Динамо-Киев|Шахтер-Донецк|Матч|07.07.2027|17:00|20|50|100\n' +
					'21|5|Динамо-Киев|Шахтер-Донецк|Матч|07.07.2027|17:00|1.2|1.3|1.4\n' +
					'21|6|Динамо-Киев|Шахтер-Донецк|Матч|07.07.2027|17:00|1.2|1.3|1.4\n' +
					'21|7|Манчестер Юнайтед|Шахтер-Донецк|Матч|07.07.2027|17:00|100|1.3|1.4\n' +
					'21|8|Динамо-Киев|Шахтер-Донецк|Ололо трололо|07.07.2027|17:00|1.2|1.3|1.4\n' +
					'21|9|Динамо-Киев|Шахтер-Донецк|Матч|07.07.2027|17:00|1.2|1.3|1.4\n' +
					'21|10|Динамо-Киев|Шахтер-Донецк|Матч|07.07.2027|17:00|20|50|100\n' +
					'21|11|Динамо-Киев|Шахтер-Донецк|Матч|07.07.2027|17:00|1.2|1.3|1.4\n' +
					'21|12|Динамо-Киев|Шахтер-Донецк|Матч|07.07.2027|17:00|1.2|1.3|1.4',
				time_stamp: '2020-05-24 19:18:14'
			}
		},
		{
			request: {
				ACTION: 'MoveML',
			},
			response: {
				client_trans_id: '411371842312',
				err_code: 0,
				err_descr: 'Операция выполнена успешно.',
				finance_data: '0|51510|03.12.2020 09:39|77009\n95|5|Не вистачає білетів\n96|КОЛЕСО УДАЧІ|Серія 01|661|36|19.65|707.4\n98|0661-000105-029-048...|\n97|0661-000105-050-045|22\n98|0661-000105-002-037...|\n97|0661-000105-005-040|4\n98|0661-000105-013-040...|\n97|0661-000105-014-041|2\n98|0661-000104-005-032...|\n97|0661-000104-007-034|3\n98|0661-000104-002-029...|\n97|0661-000104-003-030|2\n98|0661-000104-029-040,0661-000104-031-034\n98|0661-000104-043-038\n96|Щаслива 13|Серія 11|637|471|5|2355\n98|0637-000017-001-036...|\n97|0637-000017-100-039|100\n98|0637-000030-001-012...|\n97|0637-000030-100-015|100\n98|0637-000019-001-052...|\n97|0637-000019-100-055|100\n98|0637-000016-001-028...|\n97|0637-000016-100-031|100\n98|0637-000018-032-051...|\n97|0637-000018-100-047|69\n98|0637-000018-002-045,0637-000018-008-051\n95|6|Розбіжностей не виявлено\n96|Щаслива 13|Серія 11|637|4|5|20\n98|0637-000018-028-055...|\n97|0637-000018-031-050|4\n',
				// finance_data: '0|51510|03.12.2020 09:39|77009\n95|5|Не вистачає білетів\n96|КОЛЕСО УДАЧІ|Серія 01|661|36|19.65|707.4\n98|0661-000105-029-048...|\n97|0661-000105-050-045|22\n',
				lang: 'ru',
				time_stamp: '2020-12-02 17:01:00'
			}
		}
	],
	BrsDev: [{
		request: {
			ACTION: 'UpdateDrawInfo'
		},
		response: {
			client_trans_id: '406793999363',
			err_code: 0,
			err_descr: 'Операція виконана успішно.',
			lang: 'ua',
			lotteries: [
				{
					lott_name: 'Лото-Забава',
					lott_code: '3',
					currency: '',
					draws: [
						{
							draw: {
								bet_sum: '20.00',
								code: '4642',
								data: {
									bet_sum_max: '22500.00',
									bet_sum_min: '100.00',
									max_win_sum: '0.00'
								},
								dr_bdate: '2020-10-01T20:09:28',
								dr_edate: '2020-12-03T20:09:29',
								jackpot: 34142,
								num: '1768',
								sale_bdate: '2020-10-03T20:09:27',
								sale_edate: '2020-12-03T20:09:27',
								serie_code: '20',
								serie_num: '',
								status: 'Регистрация ставок',
								status_code: 'DRST_BET',
								version: '2',
								win_bdate: '2020-12-03T20:10:30',
								win_edate: '2021-10-03T20:09:27'
							}
						}
					]
				},
				{
					client_trans_id: '411497361159',
					err_code: 0,
					err_descr: 'Операція виконана успішно.',
					lotteries: [{
						lottery: {
							currency: '',
							draws: [{
								draw: {
									bet_sum: '5.00',
									code: '4785',
									data: {
										bet_sum_max: '22500.00',
										bet_sum_min: '100.00',
										max_win_sum: '0.00',
										mega: {
											extra_max: '9.00',
											extra_sum: '1.00'
										},
										mega_prize: 3000
									},
									dr_bdate: '2020-12-26 14:32:31',
									dr_edate: '2020-12-26 14:32:32',
									jackpot: 1200000,
									num: '1856',
									sale_bdate: '2020-11-20 14:32:30',
									sale_edate: '2020-12-26 14:32:30',
									serie_code: '5',
									serie_num: '',
									status: 'Регистрация ставок',
									status_code: 'DRST_BET',
									version: '4',
									win_bdate: '2020-12-26 14:32:33',
									win_edate: '2022-11-20 14:32:30'
								}
							},      {
								draw: {
									bet_sum: '5.00',
									code: '4786',
									data: {
										bet_sum_max: '22500.00',
										bet_sum_min: '100.00',
										max_win_sum: '0.00',
										mega: {
											extra_max: '9.00',
											extra_sum: '1.00'
										},
										mega_prize: 200000
									},
									dr_bdate: '2021-03-20 14:34:12',
									dr_edate: '2021-03-20 14:34:13',
									jackpot: 300000,
									num: '1857',
									sale_bdate: '2020-11-20 14:34:16',
									sale_edate: '2021-03-20 14:34:11',
									serie_code: '5',
									serie_num: '',
									status: 'Регистрация многотиражных ставок',
									status_code: 'DRST_BET_MULTI',
									version: '2',
									win_bdate: '2021-03-20 14:34:14',
									win_edate: '2022-11-20 14:34:16'
								}
							}],
							lott_code: '1',
							lott_name: 'МЕГАЛОТ'
						}
					}],
					sid,
					time_stamp,
					user_id: '431550'
				},
				{
					lottery: {
						currency: '',
						draws: [
							{
								draw: {
									code: '65532',
									remove: 'true'
								}
							}
						],
						lott_code: '80',
						lott_name: 'ГАРЯЧІ ГРОШІ'
					}
				},
				{
					lottery: {
						currency: '',
						draws: [
							{
								draw: {
									bet_sum: '0.01',
									code: '30',
									data: {
										bet_sum_max: '22500.00',
										bet_sum_min: '100.00',
										max_win_sum: '0.00'
									},
									dr_bdate: '2022-05-29 00:00:00',
									dr_edate: '2022-05-30 00:00:00',
									num: '30',
									sale_bdate: '2016-07-31 00:00:00',
									sale_edate: '2022-05-28 00:00:00',
									serie_code: '0',
									serie_num: '',
									status: 'Регистрация ставок',
									status_code: 'DRST_BET',
									version: '2',
									win_bdate: '2022-05-31 00:00:00',
									win_edate: '2022-06-01 00:00:00'
								}
							}
						],
						lott_code: '101',
						lott_name: 'Послуги'
					}
				}
			],
			sid,
			time_stamp,
			user_id: '10568'
		}
	}],
	BRSAltWeb: [
		{
			request: {
				ACTION: 'GetDocument',
				DOCUMENT_ID: '2'
			},
			response: {
				client_id: 1,
				client_trans_id: '391636984593',
				doc: {
					doc_data: '',
					doc_templ_url: '/esap/templates/doc-1-01.xml'
				},
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				time_stamp
			}
		},
		{
			request: {
				ACTION: 'GetBlankInfo',
				MACCODE: '123456789123456789'
			},
			response: {
				client_id: 1,
				client_trans_id: '391636984593',
				price: 4000,
				add_comb_cnt: 3,
				err_code: 0,
				err_descr: 'Операція виконана успішно.'
			}
		},
		{
			request: {
				ACTION: 'GetBlankInfo',
				MACCODE: '123456789987654321'
			},
			response: {
				client_id: 1,
				client_trans_id: '391636984593',
				price: 7000,
				add_comb_cnt: 2,
				err_code: 0,
				err_descr: 'Операція виконана успішно.'
			}
		},

		{
			request: {
				ACTION: 'GetBlankInfo',
				MACCODE: '987654321123456789'
			},
			response: {
				client_id: 1,
				client_trans_id: '391636984593',
				price: 8000,
				add_comb_cnt: 8,
				err_code: 0,
				err_descr: 'Операція виконана успішно.'
			}
		},

		{
			request: {
				ACTION: 'GetBlankInfo',
				MACCODE: '666666666666666666'
			},
			response: {
				client_trans_id: "432868091651",
				err_code: 876,
				err_descr: "Бланк не найден.",
				time_stamp: "2023-08-01 14:56:22"
			}
		},

		{
			request: {
				ACTION: 'UpdateDrawInfo'
			},
			response: {
				"client_trans_id": "421433163269",
				"err_code": 0,
				"err_descr": "Операція виконана успішно.",
				"lotteries": [
					{
						"lottery": {
							"currency": "",
							"draws": [
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "1133",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "10000.00"
										},
										"dr_bdate": "2010-12-31 00:00:08",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "ХТО-ТАМ",
										"num": "ХТО ТАМ, серия 03",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "12",
										"serie_name": "ХТО ТАМ, серия 03",
										"serie_num": "ХТО ТАМ, серия 03",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "12",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "4.00",
										"code": "2082",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "20000.00"
										},
										"dr_bdate": "2009-10-06 15:19:31",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Зорепад",
										"num": "серия  01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "40",
										"serie_name": "Зорепад 01",
										"serie_num": "Зорепад 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "16",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "2145",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2009-12-27 16:24:02",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Справжнє золото",
										"num": "Справжнє золото 02",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "45",
										"serie_name": "Справжнє золото 02",
										"serie_num": "Справжнє золото 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "19",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "2196",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2010-03-07 19:05:04",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Золота сімка",
										"num": "Золота сімка, серія 1",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "48",
										"serie_name": "Золота сімка, серія 1",
										"serie_num": "Золота сімка, серія 1",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "17",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "2289",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2010-07-15 14:42:00",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Золота  копальня",
										"num": "Золота копальня 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "49",
										"serie_name": "Золота копальня 01",
										"serie_num": "Золота копальня 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "17",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "2305",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2010-08-01 19:59:27",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Багатство фараонів",
										"num": "Багатство фараонів 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "51",
										"serie_name": "Багатство фараонів 01",
										"serie_num": "Багатство фараонів 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "16",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "2549",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2011-06-20 17:45:13",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Десять перлин",
										"num": "Десять перлин 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "54",
										"serie_name": "Десять перлин 01",
										"serie_num": "Десять перлин 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "21",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "2434",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2011-01-21 20:32:29",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Золота сімка",
										"num": "Золота сімка, серія 2",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "59",
										"serie_name": "Золота сімка, серія 2",
										"serie_num": "Золота сімка, серія 2",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "17",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "4.00",
										"code": "2462",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "20000.00"
										},
										"dr_bdate": "2011-02-24 18:51:18",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Купа  грошей",
										"num": "Купа грошей, серия 02",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "61",
										"serie_name": "Купа грошей, серия 02",
										"serie_num": "Купа грошей, серия 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "16",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "2507",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2011-04-24 16:19:34",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Справжнє золото",
										"num": "Справжнє золото 03",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "64",
										"serie_name": "Справжнє золото 03",
										"serie_num": "Справжнє золото 03",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "16",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "2551",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2011-06-20 18:53:02",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Королівська сімка",
										"num": "Королівська сімка 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "67",
										"serie_name": "Королівська сімка 01",
										"serie_num": "Королівська сімка 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "17",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "2661",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2011-11-08 15:44:34",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Три п'ятірки",
										"num": "Три п'ятірки 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "70",
										"serie_name": "Три п'ятірки 01",
										"serie_num": "Три п'ятірки 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "22",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "2660",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2011-11-07 15:47:51",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Чарівна  пара",
										"num": "Чарівна пара 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "72",
										"serie_name": "Чарівна пара 01",
										"serie_num": "Чарівна пара 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "20",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "2703",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2011-12-25 18:15:33",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Королівська сімка",
										"num": "Королівська сімка 02",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "78",
										"serie_name": "Королівська сімка 02",
										"serie_num": "Королівська сімка 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "17",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "2865",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2012-06-26 15:10:14",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Королівська велич ",
										"num": "Королівська велич 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "80",
										"serie_name": "Королівська велич 01",
										"serie_num": "Королівська велич 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "18",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "2837",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2012-05-24 11:23:18",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Золото та срібло",
										"num": "Золото та Срібло 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "81",
										"serie_name": "Золото та Срібло 01",
										"serie_num": "Золото та Срібло 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "16",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "2727",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2012-01-26 19:32:36",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Багатство фараонів",
										"num": "Багатство фараонів 02",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "83",
										"serie_name": "Багатство фараонів 02",
										"serie_num": "Багатство фараонів 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "16",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "2770",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2012-03-11 14:39:42",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Три п'ятірки",
										"num": "Три п'ятірки 02",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "84",
										"serie_name": "Три п'ятірки 02",
										"serie_num": "Три п'ятірки 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "20",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "2937",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2012-09-26 16:55:05",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Святкова ",
										"num": "Святкова 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "86",
										"serie_name": "Святкова 01",
										"serie_num": "Святкова 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "27",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "2935",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2012-09-25 17:23:04",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Голкіпер",
										"num": "Голкіпер 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "87",
										"serie_name": "Голкіпер 01",
										"serie_num": "Голкіпер 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "21",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "2975",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2012-11-01 14:53:46",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Щаслива подорож",
										"num": "Щаслива подорож 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "88",
										"serie_name": "Щаслива подорож 01",
										"serie_num": "Щаслива подорож 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "19",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "2752",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2012-02-21 17:11:02",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Золота сімка",
										"num": "Золота сімка, серія 3",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "90",
										"serie_name": "Золота сімка, серія 3",
										"serie_num": "Золота сімка, серія 3",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "17",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "4.00",
										"code": "2833",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "20000.00"
										},
										"dr_bdate": "2012-05-19 10:43:23",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Зорепад",
										"num": "Серия 02",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "97",
										"serie_name": "Зорепад 02",
										"serie_num": "Зорепад 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "17",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "2894",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2012-08-01 11:58:01",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Королівська сімка",
										"num": "Королівська  сімка 03",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "98",
										"serie_name": "Королівська  сімка 03",
										"serie_num": "Королівська  сімка 03",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "22",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "4.00",
										"code": "3006",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "20000.00"
										},
										"dr_bdate": "2012-12-03 13:15:03",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Зорепад",
										"num": "Cерия 03",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "103",
										"serie_name": "Зорепад 03",
										"serie_num": "Зорепад 03",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "16",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "3022",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2012-12-22 12:03:15",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Десять перлин",
										"num": "Десять перлин 02",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "104",
										"serie_name": "Десять перлин 02",
										"serie_num": "Десять перлин 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "16",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "3054",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2013-01-31 12:32:41",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Золота сімка",
										"num": "Золота сімка, серія 4",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "105",
										"serie_name": "Золота сімка, серія 4",
										"serie_num": "Золота сімка, серія 4",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "15",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "4.00",
										"code": "3965",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "0.00"
										},
										"dr_bdate": "2014-05-11 21:13:40",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Товстий-гаманець",
										"num": "Товстий гаманець",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "106",
										"serie_name": "Товстий гаманець",
										"serie_num": "Товстий гаманець",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "3",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "40.00",
										"code": "3021",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2012-12-22 11:51:38",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Мільйонер",
										"num": "Мільйонер 02",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "109",
										"serie_name": "Мільйонер 02",
										"serie_num": "Мільйонер 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "17",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "4.00",
										"code": "3063",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "20000.00"
										},
										"dr_bdate": "2013-02-08 17:51:35",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Багата ферма",
										"num": "Багата ферма 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "111",
										"serie_name": "Багата ферма 01",
										"serie_num": "Багата ферма 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "3066",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2013-02-10 09:02:01",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "3 перця",
										"num": "Три перця 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "112",
										"serie_name": "Три перця 01",
										"serie_num": "Три перця 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "16",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "40.00",
										"code": "3038",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2013-01-11 19:31:50",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Мільйонер",
										"num": "Мільйонер 03",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "113",
										"serie_name": "Мільйонер 03",
										"serie_num": "Мільйонер 03",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "17",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "3055",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2013-12-31 00:00:00",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Справжнє золото",
										"num": "Справжнє золото 04",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "114",
										"serie_name": "Справжнє золото 04",
										"serie_num": "Справжнє золото 04",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "16",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "3083",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2013-02-24 16:13:21",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Три п'ятірки",
										"num": "Три п'ятірки 03",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "116",
										"serie_name": "Три п'ятірки 03",
										"serie_num": "Три п'ятірки 03",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "15",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "3084",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2013-02-25 09:47:17",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Багатство фараонів",
										"num": "Багатство фараонів 03",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "117",
										"serie_name": "Багатство фараонів 03",
										"serie_num": "Багатство фараонів 03",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "40.00",
										"code": "3111",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2013-03-29 15:26:23",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Мільйонер",
										"num": "Мільйонер 04",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "118",
										"serie_name": "Мільйонер 04",
										"serie_num": "Мільйонер 04",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "18",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "3110",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2013-03-29 15:08:37",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Чарівна  пара",
										"num": "Чарівна пара 02",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "119",
										"serie_name": "Чарівна пара 02",
										"serie_num": "Чарівна пара 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "21",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "40.00",
										"code": "3145",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2013-05-02 10:36:00",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Мільйонер",
										"num": "Мільйонер 05",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "122",
										"serie_name": "Мільйонер 05",
										"serie_num": "Мільйонер 05",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "15",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "4.00",
										"code": "3159",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "20000.00"
										},
										"dr_bdate": "2013-05-14 08:42:25",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Щасливі числа",
										"num": "Щасливі числа 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "123",
										"serie_name": "Щасливі числа 01",
										"serie_num": "Щасливі числа 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "3172",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2013-05-28 09:07:44",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Щаслива 13",
										"num": "Серия 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "124",
										"serie_name": "01",
										"serie_num": "01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "15",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "3153",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2013-05-09 14:43:13",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Рубіни",
										"num": "Рубіни 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "126",
										"serie_name": "Рубіни 01",
										"serie_num": "Рубіни 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "31",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "40.00",
										"code": "3186",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2013-06-15 10:23:14",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Мільйонер",
										"num": "Мільйонер 06",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "129",
										"serie_name": "Мільйонер 06",
										"serie_num": "Мільйонер 06",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "21",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "3184",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2013-06-11 17:58:35",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Золота  копальня",
										"num": "Золота копальня 03",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "131",
										"serie_name": "Золота копальня 03",
										"serie_num": "Золота копальня 03",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "19",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "40.00",
										"code": "3194",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2013-06-20 16:39:54",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Мільйонер",
										"num": "Мільйонер 07",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "133",
										"serie_name": "Мільйонер 07",
										"serie_num": "Мільйонер 07",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "15",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "3288",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2013-10-09 11:16:03",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Десять перлин",
										"num": "Десять перлин 03",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "134",
										"serie_name": "Десять перлин 03",
										"serie_num": "Десять перлин 03",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "3417",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2013-11-14 18:17:17",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Золота сімка",
										"num": "Золота сімка, серія 5",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "135",
										"serie_name": "Золота сімка, серія 5",
										"serie_num": "Золота сімка, серія 5",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "3439",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2013-11-21 17:52:10",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Святкова ",
										"num": "Святкова 02",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "136",
										"serie_name": "Святкова 02",
										"serie_num": "Святкова 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "15",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "4.00",
										"code": "3193",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "20000.00"
										},
										"dr_bdate": "2013-06-20 16:20:06",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Багата ферма",
										"num": "Багата ферма 02",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "137",
										"serie_name": "Багата ферма 02",
										"serie_num": "Багата ферма 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "16",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "3457",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2013-11-27 13:10:18",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "3 перця",
										"num": "Три перця 02",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "138",
										"serie_name": "Три перця 02",
										"serie_num": "Три перця 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "40.00",
										"code": "3287",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2013-10-08 15:00:02",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Мільйонер",
										"num": "Мільйонер 08",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "139",
										"serie_name": "Мільйонер 08",
										"serie_num": "Мільйонер 08",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "20",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "3479",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2013-12-04 17:26:59",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Голкіпер",
										"num": "Голкіпер 02",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "140",
										"serie_name": "Голкіпер 02",
										"serie_num": "Голкіпер 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "17",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "4.00",
										"code": "3320",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "20000.00"
										},
										"dr_bdate": "2013-10-17 18:30:10",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Купа  грошей",
										"num": "Купа грошей, серия 04",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "142",
										"serie_name": "Купа грошей, серия 04",
										"serie_num": "Купа грошей, серия 04",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "15",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "3465",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2013-11-30 09:11:53",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Спринт",
										"num": "Спринт 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "143",
										"serie_name": "Спринт 01",
										"serie_num": "Спринт 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "17",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "3612",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2014-01-14 12:59:26",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Супер 7",
										"num": "Серія 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "144",
										"serie_name": "Серія 01",
										"serie_num": "Серія 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "4.00",
										"code": "3563",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "20000.00"
										},
										"dr_bdate": "2013-12-27 19:35:12",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Пачка грошей",
										"num": "Пачка грошей 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "145",
										"serie_name": "Пачка грошей 01",
										"serie_num": "Пачка грошей 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "13",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "40.00",
										"code": "3432",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2013-11-19 08:28:52",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Супер золото",
										"num": "Супер золото 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "146",
										"serie_name": "Супер золото 01",
										"serie_num": "Супер золото 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "16",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "40.00",
										"code": "3988",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2014-05-19 15:17:43",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Ювілейна",
										"num": "Ювілейна 01",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "147",
										"serie_name": "Ювілейна 01",
										"serie_num": "Ювілейна 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "15",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "40.00",
										"code": "3558",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2013-12-26 13:05:22",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Мільйонер",
										"num": "Мільйонер 09",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "148",
										"serie_name": "Мільйонер 09",
										"serie_num": "Мільйонер 09",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "17",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "40.00",
										"code": "3711",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2014-02-15 11:46:24",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Мільйонер",
										"num": "Мільйонер 10",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "149",
										"serie_name": "Мільйонер 10",
										"serie_num": "Мільйонер 10",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "16",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "4077",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2014-06-15 17:45:30",
										"dr_edate": "2026-04-01 00:00:00",
										"inst_lott_name": "Великі гроші",
										"num": "Великі гроші 01",
										"sale_bdate": "2014-06-16 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "151",
										"serie_name": "Великі гроші 01",
										"serie_num": "Великі гроші 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "15",
										"win_bdate": "2014-06-16 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "4146",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2014-07-09 14:43:47",
										"dr_edate": "2026-04-01 00:00:00",
										"inst_lott_name": "Щаслива 13",
										"num": "Серия 02",
										"sale_bdate": "2014-07-10 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "152",
										"serie_name": "Серия 02",
										"serie_num": "Серия 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2014-07-10 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "4076",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2014-06-15 15:58:49",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Королівська сімка",
										"num": "Королівська сімка 04",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "153",
										"serie_name": "Королівська сімка 04",
										"serie_num": "Королівська сімка 04",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "16",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "40.00",
										"code": "4078",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2014-06-15 18:46:39",
										"dr_edate": "2026-04-01 23:59:00",
										"inst_lott_name": "Мільйонер",
										"num": "Мільйонер 11",
										"sale_bdate": "2016-02-08 00:00:00",
										"sale_edate": "2026-04-01 23:59:00",
										"serie_code": "154",
										"serie_name": "Мільйонер 11",
										"serie_num": "Мільйонер 11",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "16",
										"win_bdate": "2016-02-08 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "4200",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2014-07-28 15:23:59",
										"dr_edate": "2026-04-01 00:00:00",
										"inst_lott_name": "Супер 7",
										"num": "Серія 02",
										"sale_bdate": "2014-07-31 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "156",
										"serie_name": "Серія 02",
										"serie_num": "Серія 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "26",
										"win_bdate": "2014-07-31 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "4201",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2014-07-28 15:57:39",
										"dr_edate": "2026-04-01 00:00:00",
										"inst_lott_name": "Рубіни",
										"num": "Рубіни 02",
										"sale_bdate": "2014-07-31 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "157",
										"serie_name": "Рубіни 02",
										"serie_num": "Рубіни 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "15",
										"win_bdate": "2014-07-31 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "4260",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2014-08-16 20:12:41",
										"dr_edate": "2026-04-01 00:00:00",
										"inst_lott_name": "Десять перлин",
										"num": "Десять перлин 04",
										"sale_bdate": "2014-08-21 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "158",
										"serie_name": "Десять перлин 04",
										"serie_num": "Десять перлин 04",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2014-08-21 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "4288",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2014-08-24 16:28:27",
										"dr_edate": "2026-04-01 00:00:00",
										"inst_lott_name": "Три п'ятірки",
										"num": "Три п'ятірки 04",
										"sale_bdate": "2014-08-28 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "159",
										"serie_name": "Три п'ятірки 04",
										"serie_num": "Три п'ятірки 04",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "19",
										"win_bdate": "2014-08-28 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "4259",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2014-08-16 20:02:19",
										"dr_edate": "2026-04-01 00:00:00",
										"inst_lott_name": "Королівська велич ",
										"num": "Королівська велич 03",
										"sale_bdate": "2014-08-21 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "160",
										"serie_name": "Королівська велич 03",
										"serie_num": "Королівська велич 03",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "15",
										"win_bdate": "2014-08-21 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "4287",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2014-08-24 15:56:20",
										"dr_edate": "2026-04-01 00:00:00",
										"inst_lott_name": "Справжнє золото",
										"num": "Справжнє золото 05",
										"sale_bdate": "2014-08-28 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "161",
										"serie_name": "Справжнє золото 05",
										"serie_num": "Справжнє золото 05",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "15",
										"win_bdate": "2014-08-28 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "40.00",
										"code": "4256",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2014-11-14 00:00:00",
										"dr_edate": "2026-04-01 00:00:00",
										"inst_lott_name": "Супер золото",
										"num": "Супер золото 02",
										"sale_bdate": "2014-11-14 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "162",
										"serie_name": "Супер золото 02",
										"serie_num": "Супер золото 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "15",
										"win_bdate": "2014-11-14 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "40.00",
										"code": "4205",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2014-07-29 13:44:12",
										"dr_edate": "2026-04-01 00:00:00",
										"inst_lott_name": "Мільйонер",
										"num": "Мільйонер 12",
										"sale_bdate": "2014-07-31 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "165",
										"serie_name": "Мільйонер 12",
										"serie_num": "Мільйонер 12",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "17",
										"win_bdate": "2014-07-31 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "4707",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2015-01-12 17:29:12",
										"dr_edate": "2026-04-01 00:00:00",
										"inst_lott_name": "Фартова 7",
										"num": "Серія 01",
										"sale_bdate": "2015-01-16 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "166",
										"serie_name": "Серія 01",
										"serie_num": "Серія 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "13",
										"win_bdate": "2015-01-16 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "4653",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2014-12-24 12:52:53",
										"dr_edate": "2026-04-01 00:00:00",
										"inst_lott_name": "Ювелірна",
										"num": "Серія 01",
										"sale_bdate": "2014-12-26 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "167",
										"serie_name": "Серія 01",
										"serie_num": "Серія 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "21",
										"win_bdate": "2014-12-26 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "40.00",
										"code": "5210",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "2000000.00"
										},
										"dr_bdate": "2015-07-01 14:19:52",
										"dr_edate": "2015-07-01 14:19:52",
										"inst_lott_name": "Мільйонер",
										"num": "Мільйонер 13",
										"sale_bdate": "2015-07-02 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "168",
										"serie_name": "Мільйонер 13",
										"serie_num": "Мільйонер 13",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "13",
										"win_bdate": "2015-07-02 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "4771",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2015-02-03 17:45:33",
										"dr_edate": "2026-04-01 00:00:00",
										"inst_lott_name": "Золота сімка",
										"num": "Золота сімка, серія 6",
										"sale_bdate": "2015-02-05 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "169",
										"serie_name": "Золота сімка, серія 6",
										"serie_num": "Золота сімка, серія 6",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "16",
										"win_bdate": "2015-02-05 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "4772",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "250000.00"
										},
										"dr_bdate": "2015-02-03 18:01:14",
										"dr_edate": "2026-04-01 00:00:00",
										"inst_lott_name": "3 перця",
										"num": "Три перця 03",
										"sale_bdate": "2015-02-05 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "170",
										"serie_name": "Три перця 03",
										"serie_num": "Три перця 03",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2015-02-05 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "5034",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "500000.00"
										},
										"dr_bdate": "2015-05-03 14:28:18",
										"dr_edate": "2015-05-03 14:28:18",
										"inst_lott_name": "Багатство фараонів",
										"num": "Багатство фараонів 04",
										"sale_bdate": "2015-05-06 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "171",
										"serie_name": "Багатство фараонів 04",
										"serie_num": "Багатство фараонів 04",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "15",
										"win_bdate": "2015-05-06 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "5082",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "200000.00"
										},
										"dr_bdate": "2015-05-19 19:58:33",
										"dr_edate": "2015-05-19 19:58:33",
										"inst_lott_name": "Золота  копальня",
										"num": "Золота копальня 04",
										"sale_bdate": "2015-05-21 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "174",
										"serie_name": "Золота копальня 04",
										"serie_num": "Золота копальня 04",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2015-05-21 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "5083",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "200000.00"
										},
										"dr_bdate": "2015-05-20 12:50:09",
										"dr_edate": "2015-05-20 12:50:09",
										"inst_lott_name": "Королівська сімка",
										"num": "Королівська сімка 05",
										"sale_bdate": "2015-05-21 00:00:00",
										"sale_edate": "2026-04-01 00:00:00",
										"serie_code": "175",
										"serie_name": "Королівська сімка 05",
										"serie_num": "Королівська сімка 05",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "13",
										"win_bdate": "2015-05-21 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "5.00",
										"code": "929",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "5000.00"
										},
										"dr_bdate": "2017-05-12 11:55:38",
										"dr_edate": "2017-05-12 11:55:38",
										"inst_lott_name": "Багата ферма",
										"num": "Багата ферма 11",
										"sale_bdate": "2017-05-15 00:00:00",
										"sale_edate": "2026-06-30 00:00:00",
										"serie_code": "622",
										"serie_name": "Багата ферма 11",
										"serie_num": "Багата ферма 11",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "20",
										"win_bdate": "2017-05-15 00:00:00",
										"win_edate": "2026-08-22 00:00:00"
									}
								},
								{
									"draw": {
										"bet_sum": "9.80",
										"code": "2165",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "200000.00"
										},
										"dr_bdate": "2018-05-31 15:23:09",
										"dr_edate": "2018-05-31 15:23:09",
										"inst_lott_name": "Справжнє золото",
										"num": "Справжнє золото 11",
										"sale_bdate": "2018-06-01 08:00:00",
										"sale_edate": "2026-01-31 23:59:00",
										"serie_code": "636",
										"serie_name": "Серія 11",
										"serie_num": "Серія 11",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "13",
										"win_bdate": "2018-06-01 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "9.80",
										"code": "2168",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "200000.00"
										},
										"dr_bdate": "2018-06-01 10:19:40",
										"dr_edate": "2018-06-01 10:19:40",
										"inst_lott_name": "Щаслива 13",
										"num": "Серия 11",
										"sale_bdate": "2018-06-01 08:00:00",
										"sale_edate": "2026-01-31 23:59:00",
										"serie_code": "637",
										"serie_name": "Серия 11",
										"serie_num": "Серия 11",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "12",
										"win_bdate": "2018-06-01 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "49.55",
										"code": "2164",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "2000000.00"
										},
										"dr_bdate": "2018-05-31 15:14:37",
										"dr_edate": "2018-05-31 15:14:37",
										"inst_lott_name": "Золото ацтеків",
										"num": "Серия 01",
										"sale_bdate": "2018-06-01 08:00:00",
										"sale_edate": "2026-01-31 23:59:00",
										"serie_code": "660",
										"serie_name": "Серия 01",
										"serie_num": "Серия 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "11",
										"win_bdate": "2018-06-01 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "19.65",
										"code": "2163",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "500000.00"
										},
										"dr_bdate": "2018-05-31 14:52:14",
										"dr_edate": "2018-05-31 14:52:14",
										"inst_lott_name": "КОЛЕСО УДАЧІ",
										"num": "Серия 01",
										"sale_bdate": "2018-06-01 08:00:00",
										"sale_edate": "2026-01-31 23:59:00",
										"serie_code": "661",
										"serie_name": "Серия 01",
										"serie_num": "Серия 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "12",
										"win_bdate": "2018-06-01 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "49.55",
										"code": "2162",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "2000000.00"
										},
										"dr_bdate": "2018-05-31 14:41:51",
										"dr_edate": "2018-05-31 14:41:51",
										"inst_lott_name": "ЧОРНА ПЕРЛИНА",
										"num": "Серия 01",
										"sale_bdate": "2018-06-01 08:00:00",
										"sale_edate": "2026-01-31 23:59:00",
										"serie_code": "662",
										"serie_name": "Серия 01",
										"serie_num": "Серия 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "11",
										"win_bdate": "2018-06-01 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "19.65",
										"code": "2166",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "500000.00"
										},
										"dr_bdate": "2018-05-31 15:28:19",
										"dr_edate": "2018-05-31 15:28:19",
										"inst_lott_name": "Твій сейф",
										"num": "Твій сейф,серія 11",
										"sale_bdate": "2018-06-01 08:00:00",
										"sale_edate": "2026-01-31 23:59:00",
										"serie_code": "663",
										"serie_name": "Серія 11",
										"serie_num": "Серія 11",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "15",
										"win_bdate": "2018-06-01 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "9.80",
										"code": "3076",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "200000.00"
										},
										"dr_bdate": "2019-03-20 17:04:43",
										"dr_edate": "2019-03-20 17:04:43",
										"inst_lott_name": "Справжнє золото",
										"num": "Серія 12",
										"sale_bdate": "2019-04-02 08:00:00",
										"sale_edate": "2026-01-31 23:59:00",
										"serie_code": "679",
										"serie_name": "Серія 12",
										"serie_num": "Серія 12",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "15",
										"win_bdate": "2019-04-02 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "9.80",
										"code": "3064",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "200000.00"
										},
										"dr_bdate": "2019-03-17 15:17:00",
										"dr_edate": "2019-03-17 15:17:00",
										"inst_lott_name": "Щаслива 13",
										"num": "Серия 12",
										"sale_bdate": "2019-04-02 08:00:00",
										"sale_edate": "2026-01-31 23:59:00",
										"serie_code": "680",
										"serie_name": "Серия 12",
										"serie_num": "Серия 12",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "20",
										"win_bdate": "2019-04-02 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "4833",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "500000.00"
										},
										"dr_bdate": "2020-08-06 17:27:12",
										"dr_edate": "2020-08-06 17:27:12",
										"inst_lott_name": "КОЛЕСО УДАЧІ",
										"num": "02",
										"sale_bdate": "2020-08-10 08:00:00",
										"sale_edate": "2026-01-31 23:59:00",
										"serie_code": "682",
										"serie_name": "02",
										"serie_num": "02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "12",
										"win_bdate": "2020-08-10 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "49.55",
										"code": "3089",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "2000000.00"
										},
										"dr_bdate": "2019-03-24 10:15:11",
										"dr_edate": "2019-03-24 10:15:11",
										"inst_lott_name": "Золото ацтеків",
										"num": "Серия 02",
										"sale_bdate": "2019-04-01 08:00:00",
										"sale_edate": "2026-01-31 23:59:00",
										"serie_code": "683",
										"serie_name": "Серия 02",
										"serie_num": "Серия 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "12",
										"win_bdate": "2019-04-01 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "49.55",
										"code": "3075",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "2000000.00"
										},
										"dr_bdate": "2019-03-20 16:42:42",
										"dr_edate": "2019-03-20 16:42:42",
										"inst_lott_name": "ЧОРНА ПЕРЛИНА",
										"num": "Серия 02",
										"sale_bdate": "2019-04-01 08:00:00",
										"sale_edate": "2026-01-31 23:59:00",
										"serie_code": "684",
										"serie_name": "Серия 02",
										"serie_num": "Серия 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "12",
										"win_bdate": "2019-04-01 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "19.65",
										"code": "3699",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "500000.00"
										},
										"dr_bdate": "2019-09-23 12:22:55",
										"dr_edate": "2019-09-23 12:22:55",
										"inst_lott_name": "Карти в руки",
										"num": "Серия 01",
										"sale_bdate": "2019-09-25 08:00:00",
										"sale_edate": "2026-01-22 23:59:00",
										"serie_code": "711",
										"serie_name": "Серия 01",
										"serie_num": "Серия 01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "33",
										"win_bdate": "2019-09-25 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "9.80",
										"code": "3645",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "200000.00"
										},
										"dr_bdate": "2019-09-06 13:29:04",
										"dr_edate": "2019-09-06 13:29:04",
										"inst_lott_name": "Твоя готівка",
										"num": "серия 11",
										"sale_bdate": "2019-12-20 08:00:00",
										"sale_edate": "2026-01-22 23:59:00",
										"serie_code": "712",
										"serie_name": "серия 11",
										"serie_num": "серия 11",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2019-12-20 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "19.65",
										"code": "3644",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "500000.00"
										},
										"dr_bdate": "2019-09-06 13:15:45",
										"dr_edate": "2019-09-06 13:15:45",
										"inst_lott_name": "Десять перлин",
										"num": "Десять перлин 11",
										"sale_bdate": "2019-09-25 08:00:00",
										"sale_edate": "2026-01-22 23:59:00",
										"serie_code": "713",
										"serie_name": "Десять перлин 11",
										"serie_num": "Десять перлин 11",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "13",
										"win_bdate": "2019-09-25 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "4836",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "200000.00"
										},
										"dr_bdate": "2020-08-06 18:46:02",
										"dr_edate": "2020-08-06 18:46:02",
										"inst_lott_name": "Справжнє золото",
										"num": "Серія 13",
										"sale_bdate": "2020-08-10 08:00:00",
										"sale_edate": "2026-01-31 23:59:00",
										"serie_code": "732",
										"serie_name": "Серія 13",
										"serie_num": "Серія 13",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2020-08-10 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "4835",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "200000.00"
										},
										"dr_bdate": "2020-08-06 18:26:21",
										"dr_edate": "2020-08-06 18:26:21",
										"inst_lott_name": "Щаслива 13",
										"num": "Серия 13",
										"sale_bdate": "2020-08-10 08:00:00",
										"sale_edate": "2026-01-31 23:59:00",
										"serie_code": "733",
										"serie_name": "Серия 13",
										"serie_num": "Серия 13",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "13",
										"win_bdate": "2020-08-10 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "48.00",
										"code": "4834",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2020-08-06 18:04:44",
										"dr_edate": "2020-08-06 18:04:44",
										"inst_lott_name": "ДЕТЕКТИВ",
										"num": "01",
										"sale_bdate": "2020-08-10 08:00:00",
										"sale_edate": "2026-01-31 23:59:00",
										"serie_code": "734",
										"serie_name": "01",
										"serie_num": "01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2020-08-10 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "50.00",
										"code": "4837",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2020-08-06 19:05:24",
										"dr_edate": "2020-08-06 19:05:24",
										"inst_lott_name": "Мільйонер",
										"num": "Мільйонер 21",
										"sale_bdate": "2020-08-10 08:00:00",
										"sale_edate": "2026-01-31 23:59:00",
										"serie_code": "735",
										"serie_name": "Мільйонер 21",
										"serie_num": "Мільйонер 21",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2020-08-10 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "50.00",
										"code": "6086",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2021-06-11 19:17:57",
										"dr_edate": "2021-06-11 19:17:57",
										"inst_lott_name": "ЧОРНА ПЕРЛИНА",
										"num": "Серия 03",
										"sale_bdate": "2021-06-14 08:00:00",
										"sale_edate": "2026-01-31 23:59:00",
										"serie_code": "748",
										"serie_name": "Серия 03",
										"serie_num": "Серия 03",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "12",
										"win_bdate": "2021-06-14 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "50.00",
										"code": "5910",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "1000000.00"
										},
										"dr_bdate": "2021-04-27 11:34:30",
										"dr_edate": "2021-04-27 11:34:30",
										"inst_lott_name": "Мільйонер",
										"num": "Мільйонер 22",
										"sale_bdate": "2021-04-28 08:00:00",
										"sale_edate": "2026-03-31 23:59:00",
										"serie_code": "749",
										"serie_name": "Мільйонер 22",
										"serie_num": "Мільйонер 22",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2021-04-28 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "6091",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "500000.00"
										},
										"dr_bdate": "2021-06-11 21:14:32",
										"dr_edate": "2021-06-11 21:14:32",
										"inst_lott_name": "Карти в руки",
										"num": "Серия 02",
										"sale_bdate": "2021-06-14 08:00:00",
										"sale_edate": "2026-01-22 23:59:00",
										"serie_code": "751",
										"serie_name": "Серия 02",
										"serie_num": "Серия 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "13",
										"win_bdate": "2021-06-14 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "5911",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "500000.00"
										},
										"dr_bdate": "2021-04-27 12:43:19",
										"dr_edate": "2021-04-27 12:43:19",
										"inst_lott_name": "Десять перлин",
										"num": "Десять перлин 12",
										"sale_bdate": "2021-04-28 08:00:00",
										"sale_edate": "2026-01-22 23:59:00",
										"serie_code": "752",
										"serie_name": "Десять перлин 12",
										"serie_num": "Десять перлин 12",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2021-04-28 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "6089",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "500000.00"
										},
										"dr_bdate": "2021-06-11 20:19:42",
										"dr_edate": "2021-06-11 20:19:42",
										"inst_lott_name": "Живі гроші ",
										"num": "серия 02",
										"sale_bdate": "2021-06-14 08:00:00",
										"sale_edate": "2026-01-22 23:59:00",
										"serie_code": "755",
										"serie_name": "серия 02",
										"serie_num": "серия 02",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "14",
										"win_bdate": "2021-06-14 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "5912",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2021-04-27 13:31:06",
										"dr_edate": "2021-04-27 13:31:06",
										"inst_lott_name": "ТУДИ-СЮДИ",
										"num": "01",
										"sale_bdate": "2021-04-28 08:00:00",
										"sale_edate": "2026-03-31 23:59:00",
										"serie_code": "765",
										"serie_name": "01",
										"serie_num": "01",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "10",
										"win_bdate": "2021-04-28 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "6090",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2021-06-11 20:54:55",
										"dr_edate": "2021-06-11 20:54:55",
										"inst_lott_name": "ТУДИ-СЮДИ",
										"num": "03",
										"sale_bdate": "2021-06-14 08:00:00",
										"sale_edate": "2026-03-31 23:59:00",
										"serie_code": "772",
										"serie_name": "03",
										"serie_num": "03",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "11",
										"win_bdate": "2021-06-14 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								},
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "6088",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "500000.00"
										},
										"dr_bdate": "2021-06-11 19:47:35",
										"dr_edate": "2021-06-11 19:47:35",
										"inst_lott_name": "КОЛЕСО УДАЧІ",
										"num": "Серия 03",
										"sale_bdate": "2021-06-14 08:00:00",
										"sale_edate": "2026-01-31 23:59:00",
										"serie_code": "773",
										"serie_name": "Серия 03",
										"serie_num": "Серия 03",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "12",
										"win_bdate": "2021-06-14 08:00:00",
										"win_edate": "2026-08-22 23:59:00"
									}
								}
							],
							"lott_code": "100",
							"lott_name": "Миттєвi"
						}
					},
					{
						"lottery": {
							"currency": "",
							"draws": [
								{
									"draw": {
										"bet_sum": "10.00",
										"code": "5152",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "100000.00"
										},
										"dr_bdate": "2020-10-29 22:00:00",
										"dr_edate": "2026-08-22 23:59:59",
										"num": "17",
										"sale_bdate": "2020-10-29 09:30:28",
										"sale_edate": "2026-03-31 23:59:59",
										"serie_code": "759",
										"serie_num": "17",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "12",
										"win_bdate": "2020-10-29 09:30:28",
										"win_edate": "2026-08-22 23:59:59"
									}
								}
							],
							"lott_code": "107",
							"lott_extra": {
								"group_id": "1"
							},
							"lott_name": "Морський бій"
						}
					},
					{
						"lottery": {
							"currency": "",
							"draws": [
								{
									"draw": {
										"bet_sum": "20.00",
										"code": "4780",
										"data": {
											"bet_sum_max": "22500.00",
											"bet_sum_min": "100.00",
											"max_win_sum": "200000.00"
										},
										"dr_bdate": "2020-07-23 22:00:00",
										"dr_edate": "2026-08-22 23:59:59",
										"num": "28",
										"sale_bdate": "2020-07-23 13:46:10",
										"sale_edate": "2026-03-31 23:59:59",
										"serie_code": "756",
										"serie_num": "28",
										"status": "Продажа и выплата выигрышей",
										"status_code": "SALES AND PAYS",
										"version": "17",
										"win_bdate": "2020-07-23 13:46:10",
										"win_edate": "2026-08-22 23:59:59"
									}
								}
							],
							"lott_code": "124",
							"lott_extra": {
								"group_id": "1"
							},
							"lott_name": "Товстий гаманець"
						}
					},
					DRAWS_FOR_GAME_ZABAVA,
					DRAWS_FOR_GAME_MEGALOT,
					DRAWS_FOR_GAME_GONKA,
					DRAWS_FOR_GAME_KARE,
					DRAWS_FOR_GAME_SPORTPROGNOZ
				],
				sid,
				time_stamp,
				user_id
			}
		},
		{
			request: {
				ACTION: 'WinCheck',
				BARCODE: '1111222233334444'
			},
			response: {
				client_trans_id: '406799779844',
				draw_code: 1646,
				draw_date: '2020-03-30 13:21:15',
				draw_num: 1306,
				err_code: 0,
				err_descr: 'Операция выполнена успешно.',
				lang: 'ru',
				player_auth: 'sms',
				player_phone: '675050677',
				ticket_num: 860,
				time_stamp,
				win_allow: 1,
				win_sum: 8050,
				win_ticket_sum: 10000
			}
		},
		{
			request: {
				ACTION: 'WinCheck',
				BARCODE: '5555666677778888'
			},
			response: {
				client_trans_id: '111636975224',
				time_stamp: '2021-11-15 13:20:23',
				err_code: '0',
				additional_info: {
					seller_hint: 'Його сума більша, ніж ліміт виплати на точці продажу. Тому порадьте щасливчику звернутись на Гарячу лінію МСЛ \u2013 0 800 21 00 65. Дзвінки безкоштовні',
					seller_notice: 'Вітаємо гравця з великим виграшем!'
				},
				err_descr: 'Операція виконана успішно.',
				client_id: '1',
				term_code: '59000',
				ticket_num: '9001000186085',
				game_code: '130',
				win_sum: '2415000',
				win_ticket_sum: '3000000',
				win_allow: '0',
				document_id: '2',
				extra_win: {
					'-self-closing': 'true'
				},
				win_details: {
					'-self-closing': 'true'
				}
			}
		},
		{
			request: {
				ACTION: 'WinCheck',
				BARCODE: '1111111122222222'
			},
			response: {
				additional_info: {
					customer_hint: '',
					customer_notice: '',
					seller_hint: 'Если игрок выигрыш не получил, посоветуйте ему обратиться на Горячую линию МСЛ – 0 800 21 00 65. Звонки бесплатные.',
					seller_notice: 'Данный выигрыш уже выплачен.'
				},
				client_trans_id: '406808840710',
				draw_code: 62080,
				draw_date: '2018-08-02 16:55:01',
				draw_num: 781,
				err_code: 0,
				err_descr: 'Операция выполнена успешно.',
				lang: 'ru',
				ticket_num: 271,
				time_stamp,
				win_allow: 0,
				win_sum: 241500,
				win_ticket_sum: 300000
			}
		},
		{
			request: {
				ACTION: 'WinCheck',
				BARCODE: '3333333344444444'
			},
			response: {
				client_trans_id: '406808928515',
				draw_code: 62080,
				draw_date: '2018-08-02 16:55:01',
				draw_num: 781,
				err_code: 0,
				err_descr: 'Операция выполнена успешно.',
				lang: 'ru',
				ticket_num: 264,
				time_stamp,
				win_allow: 1,
				win_sum: 1000,
				win_ticket_sum: 1243
			}
		},
		{
			request: {
				ACTION: 'WinCheck',
				BARCODE: '0729003245090860'
			},
			response: {
				client_trans_id: '406808928515',
				draw_code: 62080,
				draw_date: '2018-08-02 16:55:01',
				draw_num: 781,
				err_code: 0,
				err_descr: 'Операция выполнена успешно.',
				lang: 'ru',
				ticket_num: '0729003245090',
				time_stamp,
				win_allow: 0,
				win_sum: 0,
				win_ticket_sum: 0,
				extra_win: [{id: '3305', pay_instantly: '1', type: 'bonus', win_allow: '1', win_sum: '10000', win_summary: 'Подарунок - лотерейні квитки на суму'}],
				game_code: 162,
				term_code: 51510
			}
		},
		{
			request: {
				ACTION: 'WinCheck',
				BARCODE: '0729003246040976'
			},
			response: {
				client_trans_id: '406808928515',
				draw_code: 62080,
				draw_date: '2018-08-02 16:55:01',
				draw_num: 781,
				err_code: 0,
				err_descr: 'Операция выполнена успешно.',
				lang: 'ru',
				ticket_num: '0729003246040976',
				time_stamp,
				win_allow: 0,
				win_sum: 0,
				win_ticket_sum: 0,
				extra_win: [{id: '3305', pay_instantly: '1', type: 'bonus', win_allow: '1', win_sum: '10000', win_summary: 'Подарунок - лотерейні квитки на суму'}],
				game_code: 162,
				term_code: 51510
			}
		},
		{
			request: {
				ACTION: 'WinCheck',
				BARCODE: '0660855604691355'
			},
			response: {
				client_trans_id: '406808928515',
				draw_code: 62080,
				draw_date: '2018-08-02 16:55:01',
				draw_num: 781,
				err_code: 0,
				err_descr: 'Операция выполнена успешно.',
				lang: 'ru',
				ticket_num: '0660855604691355',
				time_stamp,
				win_allow: 1,
				win_sum: 10000,
				win_ticket_sum: 10000,
				game_code: 163,
				term_code: 51510
			}
		},
		{
			request: {
				ACTION: 'WinCheck',
				BARCODE: '1122334455667788'
			},
			response: {
				client_trans_id: '411658642180',
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				extra_win: [],
				game_code: 140,
				lang: 'ua',
				player_auth: 'sms',
				player_phone: '681294504',
				term_code: 51510,
				ticket_num: '1122334455667788',
				time_stamp,
				win_allow: 0,
				win_sum: 0,
				win_ticket_sum: 0,
				additional_info: {
					customer_hint: '',
					customer_notice: '',
					seller_hint: 'Выплата запрещена',
					seller_notice: 'Билет без выигрыша'
				},
			}
		},
		{
			request: {
				ACTION: 'WinCheck',
				BARCODE: '763000061814877419'
			},
			response: {
				"client_trans_id": "432872638477",
				"document_id": 1,
				"err_code": 0,
				"err_descr": "Операція виконана успішно.",
				"lang": "ua",
				"time_stamp": "2023-08-01 19:52:23",
				"win_allow": 1,
				"win_net_sum": 404500,
				"win_sum": 404500,
				"win_ticket_sum": 502485,
				"win_tickets_details": [
					{
						"draw_num": "4035",
						"win_allow": "1",
						"win_sum": "2000",
						"win_ticket_sum": "2485"
					},
					{
						"draw_num": "4037",
						"win_allow": "1",
						"win_sum": "402500",
						"win_ticket_sum": "500000"
					},
					{
						"draw_num": "4035",
						"win_allow": "1",
						"win_sum": "2000",
						"win_ticket_sum": "2485"
					},
					{
						"draw_num": "4037",
						"win_allow": "1",
						"win_sum": "402500",
						"win_ticket_sum": "500000"
					},
					{
						"draw_num": "4035",
						"win_allow": "1",
						"win_sum": "2000",
						"win_ticket_sum": "2485"
					},
					{
						"draw_num": "4037",
						"win_allow": "1",
						"win_sum": "402500",
						"win_ticket_sum": "500000"
					},
					{
						"draw_num": "4035",
						"win_allow": "1",
						"win_sum": "2000",
						"win_ticket_sum": "2485"
					},
					{
						"draw_num": "4037",
						"win_allow": "1",
						"win_sum": "402500",
						"win_ticket_sum": "500000"
					},
					{
						"draw_num": "4035",
						"win_allow": "1",
						"win_sum": "2000",
						"win_ticket_sum": "2485"
					},
					{
						"draw_num": "4037",
						"win_allow": "1",
						"win_sum": "402500",
						"win_ticket_sum": "500000"
					},
					{
						"draw_num": "4035",
						"win_allow": "1",
						"win_sum": "2000",
						"win_ticket_sum": "2485"
					},
					{
						"draw_num": "4037",
						"win_allow": "1",
						"win_sum": "402500",
						"win_ticket_sum": "500000"
					},
					{
						"draw_num": "4035",
						"win_allow": "1",
						"win_sum": "2000",
						"win_ticket_sum": "2485"
					},
					{
						"draw_num": "4037",
						"win_allow": "1",
						"win_sum": "402500",
						"win_ticket_sum": "500000"
					},
					{
						"draw_num": "4035",
						"win_allow": "1",
						"win_sum": "2000",
						"win_ticket_sum": "2485"
					},
					{
						"draw_num": "4037",
						"win_allow": "1",
						"win_sum": "402500",
						"win_ticket_sum": "500000"
					},
					{
						"draw_num": "4035",
						"win_allow": "1",
						"win_sum": "2000",
						"win_ticket_sum": "2485"
					},
					{
						"draw_num": "4037",
						"win_allow": "1",
						"win_sum": "402500",
						"win_ticket_sum": "500000"
					},
					{
						"draw_num": "4035",
						"win_allow": "0",
						"win_sum": "2000",
						"win_ticket_sum": "2485"
					},
					{
						"draw_num": "4037",
						"win_allow": "0",
						"win_sum": "402500",
						"win_ticket_sum": "500000"
					}
				]
			}
		},


		{
			request: {
				ACTION: 'WinPay'
			},
			response: {
				"client_id": 687,
				"client_trans_id": "439590311700",
				"err_code": 0,
				"err_descr": "Операція виконана успішно.",
				"time_stamp": "2024-05-31 13:00:55"
			}
		},

		{
			request: {
				ACTION: 'SendAuthCode'
			},
			response: {
				client_trans_id: '407099740674',
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				time_stamp: '2020-05-23 14:29:22',
				user_id: 51508
			}
		},
		// {
		// 	request: {
		// 		ACTION: 'ZabavaRegBet'
		// 	},
		// 	response: {
		// 		client_id: 51508,
		// 		client_trans_id: '407099814915',
		// 		err_code: 0,
		// 		err_descr: 'Операція виконана успішно.',
		// 		lang: 'ua',
		// 		sid,
		// 		slogan: 'Виграє той, хто грає!\r\nТехніка Saturn -\r\nдля дому на довгі роки',
		// 		ticket: [
		// 			{
		// 				bet_count: 0,
		// 				bet_sum: '20.00',
		// 				description: 'Билет Лото-Забава N 6',
		// 				draw_date: '2020-07-21 06:43:55',
		// 				draw_num: 1742,
		// 				game_comb: [{
		// 					game_field: '01003253631318385165051600486613273753630627374773'
		// 				},          {
		// 					game_field: '02243950621426425500082900557215263560711520395671'
		// 				},          {
		// 					game_field: '10173654670422455474032500596809224549740921340069'
		// 				}],
		// 				id: '4497344957',
		// 				mac_code: '003017420000000692963569',
		// 				portion_id: 0,
		// 				regdate: '2020-05-23 14:34:11',
		// 				ticket_num: 6,
		// 				ticket_snum: 92963569
		// 			}
		// 		],
		// 		ticket_templ_url: '/esap/templates/zabava-ticket-0-utf8-28.xml',
		// 		time_stamp,
		// 		user_id: '431517'
		// 	}
		// },
		{
			request: {
				ACTION: 'ZabavaRegBet'
				// MACCODE: '123456789123456789'
			},
			response: {
				client_id: 51508,
				client_trans_id: '407099814915',
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				ticket: [
					{
						bet_count: 0,
						bet_sum: '40.00',
						description: 'Квитанция о регистрации ставок в государственную лотерею',
						draw_date: '2020-07-21 06:43:55',
						draw_num: 1742,
						id: '4497344957',
						regdate: '2020-05-23 14:34:11',
						ticket_num: 6,
						ticket_snum: 92963569,
						ticket_count: 2,
						ticket_data: [
							{
								mac_code: '1111222233334444',
								blank_num: '1111',
								blank_mac_code: '123456789123456789'
							},
							{
								mac_code: '1111111122222222',
								blank_num: '2222',
								blank_mac_code: '123456789123456789'
							}
						],
						action: 0
					}
				],
			}
		},
		{
			request: {
				ACTION: 'MegalotRegBet'
			},
			response: {
				client_trans_id: '407102146565',
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				sid,
				ticket: [
					{
						bet: [
							{
								balls: '7,26,40,12,19,8',
								input_mode: 3,
								megaballs: '3'
							}
						],
						bet_count: 1,
						bet_sum: '5.00',
						description: 'Чек Мегалот на тираж 1850',
						draw_date: '2020-06-09 12:55:52',
						draw_num: 1850,
						game_name: 'МЕГАЛОТ',
						id: '3122153980',
						mac_code: '00151508001041670000001292',
						pos_name: 'ФОП Гастроном',
						regdate: '2020-05-23 17:05:59',
						slogan: 'Трансляція розіграшу \r\nо 21:55 на телеканалі «КРТ»',
						ticket_num: 1,
						ticket_sum: 500,
						ticket_templ_url: 'esap/templates/megalot-ticket-utf8-15.xml',
						url: '/assets/img/test-ticket.png'
					}
				],
				time_stamp: '2020-05-23 17:06:01',
				user_id: '431517'
			}
		},
		{
			request: {
				ACTION: 'EInstantRegBet'
			},
			response: {
				client_id: 51508,
				client_trans_id: '407102443528',
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				oper_code: 77007,
				term_code: 51508,
				ticket: [
					{
						bet_sum: '20.00',
						date_reg: '2020-05-23 17:25:21',
						description: 'Білет N 0731-000190-007',
						game_code: 145,
						game_name: 'Швидкограй',
						gfield: [
							{
								img: '',
								sign: '    08  |  18  |  23  |  40     '
							},
							{
								img: '',
								sign: '  ------+------+------+------   '
							},
							{
								img: '',
								sign: '    :)  |  14  |  24  |  33     '
							},
							{
								img: '',
								sign: '  ------+------+------+------   '
							},
							{
								img: '',
								sign: '    05  |  17  |  29  |  31     '
							},
							{
								img: '',
								sign: '  ------+------+------+------   '
							},
							{
								img: '',
								sign: '    04  |  15  |  27  |  38     '
							},
							{
								img: '',
								sign: 'END'
							},
							{
								img: '',
								sign: '    02  |  17  |  30  |  36     '
							},
							{
								img: '',
								sign: '  ------+------+------+------   '
							},
							{
								img: '',
								sign: '    09  |  11  |  :)  |  39     '
							},
							{
								img: '',
								sign: '  ------+------+------+------   '
							},
							{
								img: '',
								sign: '    04  |  20  |  21  |  32     '
							},
							{
								img: '',
								sign: '  ------+------+------+------   '
							},
							{
								img: '',
								sign: '    06  |  16  |  25  |  31     '
							}
						],
						gfield_cnt: 7,
						id: 1154528846,
						interactive: {
							code: ''
						},
						mac_code: '0731000190007230',
						next_game: '',
						portion_id: 421886183,
						series_name: '12',
						series_num: 12,
						slogan: '',
						ticket_num: '0731-000190-007',
						wfield: [
							{
								img: '',
								sign: '01'
							},
							{
								img: '',
								sign: '28'
							},
							{
								img: '',
								sign: '12'
							},
							{
								img: '',
								sign: '36'
							},
							{
								img: '',
								sign: '03'
							},
							{
								img: '',
								sign: '29'
							},
							{
								img: '',
								sign: '37'
							},
							{
								img: '',
								sign: '16'
							},
							{
								img: '',
								sign: '26'
							},
							{
								img: '',
								sign: '19'
							},
							{
								img: '',
								sign: '35'
							},
							{
								img: '',
								sign: '34'
							},
							{
								img: '',
								sign: '10'
							},
							{
								img: '',
								sign: '13'
							},
							{
								img: '',
								sign: '22'
							},
							{
								img: '',
								sign: '08'
							},
							{
								img: '',
								sign: '07'
							},
							{
								img: '',
								sign: '15'
							}
						],
						wfield_cnt: 9
					}
				],
				ticket_templ_url: 'esap/templates/veselyk-ticket-utf8-05.xml',
				time_stamp
			}
		},
		{
			request: {
				ACTION: 'TipTopRegBet'
			},
			response: {
				client_id: 51508,
				client_trans_id: '407102656522',
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				sid,
				ticket: [
					{
						bet: [
							{
								comb: '851145',
								number: '1'
							}
						],
						bet_count: '1',
						bet_sum: '2',
						description: 'Чек Тип на тираж 02377',
						draw_code: 4268,
						draw_count: '1',
						first_draw_date: '2020-05-24 14:25:00',
						first_draw_num: '02377',
						id: '3122153982',
						last_draw_date: '',
						last_draw_num: '',
						mac_code: '07151508001042680000001594',
						mac_code_sign: '0715-1508-0010-4268-0000-0015-94',
						portion_id: '0',
						regdate: '2020-05-23 17:39:12',
						slogan: 'Дивіться розіграш щоденно \nна ЕСПРЕСО TV \nо 15:28',
						ticket_num: '1',
						ticket_templ_url: '/esap/templates/tip-ticket-utf8-14.xml'
					}
				],
				time_stamp,
				user_id: '431517'
			}
		},
		{
			request: {
				ACTION: 'GonkaRegBet'
			},
			response: {
				client_trans_id: '407102839812',
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				sid,
				ticket: [
					{
						bet: [
							{
								balls: '7,9,9,10',
								type: 'A'
							}
						],
						bet_cost: 2,
						bet_count: 1,
						bet_sum: 2,
						description: 'Чек № 14 тираж № 066',
						draw_date: '2020-02-13 13:41:00',
						draw_num: '130220/066',
						id: 2285464981,
						mac_code: '01340000003229900000014440',
						regdate: '2020-05-23 17:51:08',
						ticket_num: 14,
						ticket_templ_url: '/esap/templates/gonka-ticket-utf8-05.xml'
					}
				],
				time_stamp
			}
		},
		{
			request: {
				ACTION: 'PaperInstantRegBet'
			},
			response: {
				client_trans_id: '407102839812',
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				sid,
				ticket: [
					{
						bet_sum: '58.90',
						id: 3122245905,
						reg_date: '2020-08-27 18:01:37',
						serie: [
							{
								name: 'Десять перлин Серія 11',
								range: [
									{
										count: 2,
										numbers: '0713-000004-043...044',
										sum: '39.30'
									}
								],
								ticket_price: '19.65'
							},
							{
								name: 'Справжнє золото Серія 12',
								range: [
									{
										count: 2,
										numbers: '0679-000010-017...018',
										sum: '19.60'
									}
								],
								ticket_price: '9.80'
							}
						],
						term_code: 51501,
						ticket_templ_url: ''
					}
				],
				time_stamp
			}
		},
		{
			request: {
				ACTION: 'KareRegBet'
			},
			response: {
				client_trans_id: '407102146565',
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				sid,
				ticket: [
					{
						bet_count: 1,
						bet_sum: '5.00',
						draw_date: '2020-06-09 12:55:52',
						draw_num: 1850,
						game_name: 'Каре',
						id: '3122153980',
						mac_code: '00151508001041670000001292',
						pos_name: 'ФОП Гастроном',
						regdate: '2020-05-23 17:05:59',
						slogan: 'Трансляція розіграшу \r\nо 21:55 на телеканалі «КРТ»',
						ticket_num: 1, ticket_sum: 500, ticket_templ_url: 'esap/templates/megalot-ticket-utf8-15.xml'
					}
				],
				time_stamp: '2020-05-23 17:06:01',
				user_id: '431517'
			}
		},
		{
			request: {
				ACTION: 'GetDrawResults',
				LOTTERY: '3',
				MAX_DRAW_COUNT: '6',
				DRAWING_DATE_MAX: `${currDate} 23:59:58`
			},
			response: {
				client_trans_id: '410014638851',
				currency: '',
				draw_count: '834',
				drawing_date_max: `${currDate} 23:59:58`,
				drawing_date_min: '2000-01-01 00:00:00',
				drawing_result: [
					{
						draw_code: 4599,
						draw_id: 69328166,
						draw_name: '1763',
						drawing: [
							{
								extra_info: 'Джекпот наступн.тиражу - 3,000,000 грн.',
								name: 'Основний розiграш',
								score: '',
								win_comb: '-'
							},
							{
								extra_info: '',
								name: 'Конкурс Парочка',
								score: '',
								win_cat: [
									{
										name: 'Повний збiг',
										win_cnt: '0',
										win_sum: '300000.00'
									},
									{
										name: 'Будь-який кут з 5-ти',
										win_cnt: '0',
										win_sum: '7500.00'
									},
									{
										name: 'Будь-яка лiнiя з 3-х',
										win_cnt: '0',
										win_sum: '100.00'
									},
									{
										name: 'Верхня вершина',
										win_cnt: '0',
										win_sum: '6.22'
									}
								],
								win_comb: '-'
							},
							{
								extra_info: '',
								name: 'Конкурс Багаті та відомі',
								score: '',
								win_comb: '-'
							}
						],
						drawing_date_begin: '2020-09-20 15:10:02',
						drawing_date_end: '2020-12-17 15:16:12',
						winning_date_end: '2020-12-17 15:16:12'
					},
					{
						draw_code: 4597,
						draw_id: 69328137,
						draw_name: '1762',
						drawing: [
							{
								extra_info: 'Джекпот наступн.тиражу -  грн.',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: '3 горизонт.без підков',
										win_cnt: '0',
										win_sum: '5000000.00'
									},
									{
										name: '3 горизонт.з підков.',
										win_cnt: '1',
										win_sum: '100000.00'
									},
									{
										name: '2 горизонт.або хрест',
										win_cnt: '4',
										win_sum: '24.85'
									},
									{
										name: '1 горизонт.або 1 діаг.',
										win_cnt: '9',
										win_sum: '24.85'
									}
								],
								win_comb: '01 16 32 47 18 19 34 20 21 36 08 07 05 11 25 40 54 22 38 52 13 14 15 29 28 42 57 58 72 73 71 70 69 68 67 66 65 50 45 44 43 30 03 02 04 09 10 06 12 27 26 24 23'
							},
							{
								extra_info: '',
								name: 'Конкурс Парочка',
								score: '',
								win_cat: [
									{
										name: 'Повний збiг',
										win_cnt: '0',
										win_sum: '300000.00'
									},
									{
										name: 'Будь-який кут з 5-ти',
										win_cnt: '0',
										win_sum: '7500.00'
									},
									{
										name: 'Будь-яка лiнiя з 3-х',
										win_cnt: '0',
										win_sum: '100.00'
									},
									{
										name: 'Верхня вершина',
										win_cnt: '0',
										win_sum: '6.22'
									}
								],
								win_comb: '01 02 22 03 23 46 00 00 00'
							},
							{
								extra_info: '',
								name: 'Конкурс Багаті та відомі',
								score: '',
								win_comb: '-'
							}
						],
						drawing_date_begin: '2020-09-17 15:10:02',
						drawing_date_end: '2021-09-17 14:07:53',
						winning_date_end: '2021-09-17 14:07:53'
					},
					{
						draw_code: 4596,
						draw_id: 69328103,
						draw_name: '1761',
						drawing: [
							{
								extra_info: 'Джекпот наступн.тиражу - 5,000,000 грн.',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: '3 горизонт.без підков',
										win_cnt: '0',
										win_sum: '5000000.00'
									},
									{
										name: '3 горизонт.з підков.',
										win_cnt: '1',
										win_sum: '100000.00'
									},
									{
										name: '2 горизонт.або хрест',
										win_cnt: '0',
										win_sum: '24.85'
									},
									{
										name: '1 горизонт.або 1 діаг.',
										win_cnt: '1',
										win_sum: '24.85'
									}
								],
								win_comb: '18 26 59 49 73 69 28 36 55 62 02 08 13 45 60 74'
							},
							{
								extra_info: '',
								name: 'Конкурс Парочка',
								score: '',
								win_cat: [
									{
										name: 'Повний збiг',
										win_cnt: '0',
										win_sum: '300000.00'
									},
									{
										name: 'Будь-який кут з 5-ти',
										win_cnt: '0',
										win_sum: '7500.00'
									},
									{
										name: 'Будь-яка лiнiя з 3-х',
										win_cnt: '0',
										win_sum: '100.00'
									},
									{
										name: 'Верхня вершина',
										win_cnt: '0',
										win_sum: '6.22'
									}
								],
								win_comb: '-'
							},
							{
								extra_info: '',
								name: 'Конкурс Багаті та відомі',
								score: '',
								win_comb: '-'
							}
						],
						drawing_date_begin: '2020-09-17 14:10:01',
						drawing_date_end: '2021-09-17 12:43:37',
						winning_date_end: '2021-09-17 12:43:37'
					},
					{
						draw_code: 4564,
						draw_id: 69270358,
						draw_name: '1760',
						drawing: [
							{
								extra_info: 'Джекпот наступн.тиражу - 5,000,000 грн.',
								name: 'Основний розiграш',
								score: '',
								win_comb: '-'
							},
							{
								extra_info: '',
								name: 'Конкурс Парочка',
								score: '',
								win_cat: [
									{
										name: 'Повний збiг',
										win_cnt: '0',
										win_sum: '300000.00'
									},
									{
										name: 'Будь-який кут з 5-ти',
										win_cnt: '0',
										win_sum: '7500.00'
									},
									{
										name: 'Будь-яка лiнiя з 3-х',
										win_cnt: '0',
										win_sum: '100.00'
									},
									{
										name: 'Верхня вершина',
										win_cnt: '0',
										win_sum: '6.22'
									}
								],
								win_comb: '-'
							},
							{
								extra_info: '',
								name: 'Конкурс Багаті та відомі',
								score: '',
								win_comb: '-'
							}
						],
						drawing_date_begin: '2020-09-17 13:50:01',
						drawing_date_end: '2021-09-03 17:13:34',
						winning_date_end: '2021-09-03 17:13:34'
					},
					{
						draw_code: 4563,
						draw_id: 69270356,
						draw_name: '1759',
						drawing: [
							{
								extra_info: 'Джекпот наступн.тиражу - 324,324 грн.',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: '3 горизонт.без підков',
										win_cnt: '0',
										win_sum: '3423532.00'
									},
									{
										name: '3 горизонт.з підков.',
										win_cnt: '1',
										win_sum: '100000.00'
									},
									{
										name: '2 горизонт.або хрест',
										win_cnt: '0',
										win_sum: '63.00'
									},
									{
										name: '1 горизонт.або 1 діаг.',
										win_cnt: '13',
										win_sum: '24.85'
									}
								],
								win_comb: '04 08 20 34 53 62 75 41 52 70 05 45 48 64 12 27 42 57 72 13 28 43 58'
							},
							{
								extra_info: '',
								name: 'Конкурс Парочка',
								score: '',
								win_cat: [
									{
										name: 'Повний збiг',
										win_cnt: '0',
										win_sum: '300000.00'
									},
									{
										name: 'Будь-який кут з 5-ти',
										win_cnt: '0',
										win_sum: '7500.00'
									},
									{
										name: 'Будь-яка лiнiя з 3-х',
										win_cnt: '0',
										win_sum: '100.00'
									},
									{
										name: 'Верхня вершина',
										win_cnt: '0',
										win_sum: '6.22'
									}
								],
								win_comb: '-'
							},
							{
								extra_info: '',
								name: 'Конкурс Багаті та відомі',
								score: '',
								win_comb: '-'
							}
						],
						drawing_date_begin: '2020-09-17 12:12:22',
						drawing_date_end: '2021-09-03 17:12:28',
						winning_date_end: '2021-09-03 17:12:28'
					},
					{
						draw_code: 4562,
						draw_id: 69270354,
						draw_name: '1758',
						drawing: [
							{
								extra_info: 'Джекпот наступн.тиражу - 3,423,532 грн.',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: '3 горизонт.без підков',
										win_cnt: '0',
										win_sum: '1231331.00'
									},
									{
										name: '3 горизонт.з підков.',
										win_cnt: '1',
										win_sum: '100000.00'
									},
									{
										name: '2 горизонт.або хрест',
										win_cnt: '14',
										win_sum: '24.85'
									},
									{
										name: '1 горизонт.або 1 діаг.',
										win_cnt: '137',
										win_sum: '24.85'
									}
								],
								win_comb: '66 67 74 51 53 57 29 47 23 33 28 26 54 34 46 16 15 06 07 01 08 50 71 24 63 19 58 70 36 39 41 14 60 44 21 49 11 20 27'
							},
							{
								extra_info: '',
								name: 'Конкурс Парочка',
								score: '',
								win_cat: [
									{
										name: 'Повний збiг',
										win_cnt: '0',
										win_sum: '300000.00'
									},
									{
										name: 'Будь-який кут з 5-ти',
										win_cnt: '0',
										win_sum: '7500.00'
									},
									{
										name: 'Будь-яка лiнiя з 3-х',
										win_cnt: '1',
										win_sum: '100.00'
									},
									{
										name: 'Верхня вершина',
										win_cnt: '37',
										win_sum: '6.22'
									}
								],
								win_comb: '64 27 62 34 19 66 25 69 70'
							},
							{
								extra_info: '',
								name: 'Конкурс Багаті та відомі',
								score: '',
								win_comb: '-'
							}
						],
						drawing_date_begin: '2020-09-10 16:25:52',
						drawing_date_end: '2021-09-03 17:08:50',
						winning_date_end: '2021-09-03 17:08:50'
					}
				],
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lott_code: '3',
				lott_name: 'Лото-Забава',
				sid: 'c2b3adb0-bba1-4443-ab69-838b0194ff15',
				time_stamp: '2020-10-06 09:21:23',
				user_id: 431550
			}
		},
		{
			request: {
				ACTION: 'GetDrawResults',
				LOTTERY: '3',
				MAX_DRAW_COUNT: '6',
				DRAWING_DATE_MAX: `${currDate} 23:59:58`
			},
			response: {
				client_trans_id: '407119430403',
				currency: '',
				draw_count: '666',
				drawing_date_max: `${currDate} 23:59:58`,
				drawing_date_min: '2000-01-01 00:00:00',
				drawing_result: [
					{
						draw_code: 4207,
						draw_id: 69031385,
						draw_name: '1735',
						drawing: [
							{
								extra_info: 'Джекпот наступн.тиражу - 152,452 грн.',
								name: 'Основний розiграш',
								score: '',
								win_comb: '-'
							},
							{
								extra_info: '',
								name: 'Конкурс Парочка',
								score: '',
								win_cat: [
									{
										name: 'Повний збiг',
										win_cnt: '0',
										win_sum: '300000.00'
									},
									{
										name: 'Будь-який кут з 5-ти',
										win_cnt: '0',
										win_sum: '7500.00'
									},
									{
										name: 'Будь-яка лiнiя з 3-х',
										win_cnt: '0',
										win_sum: '100.00'
									},
									{
										name: 'Верхня вершина',
										win_cnt: '0',
										win_sum: '6.22'
									}
								],
								win_comb: '-'
							},
							{
								extra_info: '',
								name: 'Конкурс Багаті та відомі',
								score: '',
								win_comb: '-'
							}
						],
						drawing_date_begin: '2020-05-06 13:25:31',
						drawing_date_end: '2021-04-23 17:01:39',
						winning_date_end: '2021-04-23 17:01:39'
					},
					{
						draw_code: 4186,
						draw_id: 68937277,
						draw_name: '1734',
						drawing: [
							{
								extra_info: 'Джекпот наступн.тиражу - 154,541,515 грн.',
								name: 'Основний розiграш',
								score: '',
								win_comb: '-'
							},
							{
								extra_info: '',
								name: 'Конкурс Парочка',
								score: '',
								win_cat: [
									{
										name: 'Повний збiг',
										win_cnt: '0',
										win_sum: '300000.00'
									},
									{
										name: 'Будь-який кут з 5-ти',
										win_cnt: '0',
										win_sum: '7500.00'
									},
									{
										name: 'Будь-яка лiнiя з 3-х',
										win_cnt: '0',
										win_sum: '100.00'
									},
									{
										name: 'Верхня вершина',
										win_cnt: '10',
										win_sum: '6.22'
									}
								],
								win_comb: '20 41 09 46 13 31 44 04 16'
							},
							{
								extra_info: '',
								name: 'Конкурс Багаті та відомі',
								score: '',
								win_comb: '-'
							}
						],
						drawing_date_begin: '2020-04-26 18:01:20',
						drawing_date_end: '2021-05-15 18:01:19',
						winning_date_end: '2021-05-15 18:01:19'
					},
					{
						draw_code: 4185,
						draw_id: 68937215,
						draw_name: '1733',
						drawing: [
							{
								extra_info: 'Джекпот наступн.тиражу - 164,684 грн.',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: '3 горизонт.без підков',
										win_cnt: '0',
										win_sum: '200000.00'
									},
									{
										name: '3 горизонт.з підков.',
										win_cnt: '1',
										win_sum: '200000.00'
									},
									{
										name: '2 горизонт.або хрест',
										win_cnt: '1',
										win_sum: '63.00'
									},
									{
										name: '1 горизонт.або 1 діаг.',
										win_cnt: '57',
										win_sum: '24.85'
									}
								],
								win_comb: '01 02 03 04 05 06 07 08 09 10 11 12 13 14 15 30 45 60 75 74 59 44 29 28 43 58 73 72 57 42 27 26 56 71 41 40 25'
							},
							{
								extra_info: '',
								name: 'Конкурс Парочка',
								score: '',
								win_cat: [
									{
										name: 'Повний збiг',
										win_cnt: '0',
										win_sum: '300000.00'
									},
									{
										name: 'Будь-який кут з 5-ти',
										win_cnt: '0',
										win_sum: '7500.00'
									},
									{
										name: 'Будь-яка лiнiя з 3-х',
										win_cnt: '2',
										win_sum: '100.00'
									},
									{
										name: 'Верхня вершина',
										win_cnt: '36',
										win_sum: '6.22'
									}
								],
								win_comb: '75 53 41 30 62 03 64 29 54'
							},
							{
								extra_info: '',
								name: 'Конкурс Багаті та відомі',
								score: '',
								win_comb: '-'
							}
						],
						drawing_date_begin: '2020-04-23 19:00:36',
						drawing_date_end: '2021-04-15 15:22:38',
						winning_date_end: '2021-04-15 15:22:38'
					},
					{
						draw_code: 4184,
						draw_id: 68937213,
						draw_name: '1732',
						drawing: [
							{
								extra_info: 'Джекпот наступн.тиражу - 200,000 грн.',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: '3 горизонт.без підков',
										win_cnt: '1',
										win_sum: '10000000.00'
									},
									{
										name: '3 горизонт.з підков.',
										win_cnt: '0',
										win_sum: '200000.00'
									},
									{
										name: '2 горизонт.або хрест',
										win_cnt: '26',
										win_sum: '24.85'
									},
									{
										name: '1 горизонт.або 1 діаг.',
										win_cnt: '357',
										win_sum: '24.85'
									}
								],
								win_comb: '08 50 15 11 55 16 69 01 20 47 17 28 02 71 41 58 53 37 40 32 22 10 72 35 75 29 26 44 60 63 51 24 66 74 59 64'
							},
							{
								extra_info: '',
								name: 'Конкурс Парочка',
								score: '',
								win_cat: [
									{
										name: 'Повний збiг',
										win_cnt: '0',
										win_sum: '300000.00'
									},
									{
										name: 'Будь-який кут з 5-ти',
										win_cnt: '0',
										win_sum: '7500.00'
									},
									{
										name: 'Будь-яка лiнiя з 3-х',
										win_cnt: '5',
										win_sum: '100.00'
									},
									{
										name: 'Верхня вершина',
										win_cnt: '103',
										win_sum: '6.22'
									}
								],
								win_comb: '63 22 56 13 74 17 53 05 49'
							},
							{
								extra_info: '',
								name: 'Конкурс Багаті та відомі',
								score: '',
								win_comb: '-'
							}
						],
						drawing_date_begin: '2020-04-22 15:50:47',
						drawing_date_end: '2021-04-15 15:20:46',
						winning_date_end: '2021-04-15 15:20:46'
					},
					{
						draw_code: 4180,
						draw_id: 68933085,
						draw_name: '1731',
						drawing: [
							{
								extra_info: 'Джекпот наступн.тиражу - 10,000,000 грн.',
								name: 'Основний розiграш',
								score: '',
								win_comb: '-'
							},
							{
								extra_info: '',
								name: 'Конкурс Парочка',
								score: '',
								win_cat: [
									{
										name: 'Повний збiг',
										win_cnt: '0',
										win_sum: '300000.00'
									},
									{
										name: 'Будь-який кут з 5-ти',
										win_cnt: '0',
										win_sum: '7500.00'
									},
									{
										name: 'Будь-яка лiнiя з 3-х',
										win_cnt: '0',
										win_sum: '100.00'
									},
									{
										name: 'Верхня вершина',
										win_cnt: '0',
										win_sum: '6.22'
									}
								],
								win_comb: '-'
							},
							{
								extra_info: '',
								name: 'Конкурс Багаті та відомі',
								score: '',
								win_comb: '-'
							}
						],
						drawing_date_begin: '2020-04-15 18:05:41',
						drawing_date_end: '2021-04-14 13:35:49',
						winning_date_end: '2021-04-14 13:35:49'
					}
				],
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				lott_code: '3',
				lott_name: 'Лото-Забава',
				sid,
				time_stamp,
				user_id: 431517
			}
		},
		{
			request: {
				ACTION: 'GetDrawResults',
				LOTTERY: '1',
				MAX_DRAW_COUNT: '6',
				DRAWING_DATE_MAX: `${currDate} 23:59:58`
			},
			response: {
				client_trans_id: '407121472258',
				currency: '',
				draw_count: '1926',
				drawing_date_max: `${currDate} 23:59:58`,
				drawing_date_min: '2000-01-01 00:00:00',
				drawing_result: [
					{
						draw_code: 4115,
						draw_id: 68715106,
						draw_name: '1831',
						drawing: [
							{
								extra_info: 'Мега джекпот наступн.тиражу - 7,000,000 грн.',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'за 6+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 6:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 5:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 4:',
										win_cnt: '1',
										win_sum: '2.00'
									},
									{
										name: 'за 3:',
										win_cnt: '1',
										win_sum: '100.00'
									},
									{
										name: '5+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3+1:',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '1,2,10,14,28,36 :8'
							}
						],
						drawing_date_begin: '2020-05-01 17:48:35',
						drawing_date_end: '2099-05-01 17:48:34',
						winning_date_end: '2099-05-01 17:48:34'
					},
					{
						draw_code: 4166,
						draw_id: 68853246,
						draw_name: '1849',
						drawing: [
							{
								extra_info: 'Мега джекпот наступн.тиражу - 1,312,345 грн.',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'за 6+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 6:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 5:',
										win_cnt: '1',
										win_sum: '4.00'
									},
									{
										name: 'за 4:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 3:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '5+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3+1:',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '1,2,3,4,5,23 :5'
							}
						],
						drawing_date_begin: '2020-04-23 19:15:25',
						drawing_date_end: '2021-04-09 12:54:24',
						winning_date_end: '2021-04-09 12:54:24'
					},
					{
						draw_code: 4113,
						draw_id: 68713428,
						draw_name: '1828',
						drawing: [
							{
								extra_info: 'Мега джекпот наступн.тиражу - 6,000,000 грн.',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'за 6+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 6:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 5:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 4:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 3:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '5+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3+1:',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '1,2,3,4,5,6 :3'
							}
						],
						drawing_date_begin: '2020-04-15 15:16:11',
						drawing_date_end: '2020-07-15 15:16:10',
						winning_date_end: '2020-07-15 15:16:10'
					},
					{
						draw_code: 4161,
						draw_id: 68840569,
						draw_name: '1848',
						drawing: [
							{
								extra_info: 'Мега джекпот наступн.тиражу - 1,022,222 грн.',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'за 6+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 6:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 5:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 4:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 3:',
										win_cnt: '1',
										win_sum: '10.00'
									},
									{
										name: '5+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3+1:',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '1,3,4,27,28,31 :9'
							}
						],
						drawing_date_begin: '2020-04-09 13:10:34',
						drawing_date_end: '2021-04-09 13:10:33',
						winning_date_end: '2021-04-09 13:10:33'
					},
					{
						draw_code: 4160,
						draw_id: 68840551,
						draw_name: '1847',
						drawing: [
							{
								extra_info: 'Мега джекпот наступн.тиражу - 1,300,000 грн.',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'за 6+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 6:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 5:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 4:',
										win_cnt: '2',
										win_sum: '17.00'
									},
									{
										name: 'за 3:',
										win_cnt: '6',
										win_sum: '9.00'
									},
									{
										name: '5+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4+1:',
										win_cnt: '1',
										win_sum: '34.00'
									},
									{
										name: '3+1:',
										win_cnt: '3',
										win_sum: '18.00'
									}
								],
								win_comb: '1,2,3,4,34,42 :7'
							}
						],
						drawing_date_begin: '2020-04-08 17:59:06',
						drawing_date_end: '2021-04-08 16:49:07',
						winning_date_end: '2021-04-08 16:49:07'
					},
					{
						draw_code: 4159,
						draw_id: 68840541,
						draw_name: '1846',
						drawing: [
							{
								extra_info: 'Мега джекпот наступн.тиражу - 1,100,000 грн.',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'за 6+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 6:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 5:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 4:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 3:',
										win_cnt: '1',
										win_sum: '71.00'
									},
									{
										name: '5+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3+1:',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '3,5,11,12,16,23 :9'
							}
						],
						drawing_date_begin: '2020-04-08 17:35:17',
						drawing_date_end: '2021-04-08 16:48:16',
						winning_date_end: '2021-04-08 16:48:16'
					}
				],
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				lott_code: '1',
				lott_name: 'МЕГАЛОТ',
				sid,
				time_stamp,
				user_id: 431517
			}
		},
		{
			request: {
				ACTION: 'GetDrawResults',
				LOTTERY: '1',
				MAX_DRAW_COUNT: '6',
				DRAWING_DATE_MAX: `${currDate} 23:59:58`
			},
			response: {
				client_trans_id: '407121531651',
				currency: '',
				draw_count: '1890',
				drawing_date_max: `${currDate} 23:59:58`,
				drawing_date_min: '2000-01-01 00:00:00',
				drawing_result: [
					{
						draw_code: 4158,
						draw_id: 68840532,
						draw_name: '1845',
						drawing: [
							{
								extra_info: 'Мега джекпот наступн.тиражу - 9,000 грн.',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'за 6+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 6:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 5:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 4:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 3:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '5+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3+1:',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '37,38,39,40,41,42 :2'
							}
						],
						drawing_date_begin: '2020-04-08 17:20:35',
						drawing_date_end: '2021-04-08 16:47:34',
						winning_date_end: '2021-04-08 16:47:34'
					},
					{
						draw_code: 4156,
						draw_id: 68838610,
						draw_name: '1844',
						drawing: [
							{
								extra_info: 'Мега джекпот наступн.тиражу - 7,000 грн.',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'за 6+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 6:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 5:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 4:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 3:',
										win_cnt: '2',
										win_sum: '33.00'
									},
									{
										name: '5+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3+1:',
										win_cnt: '1',
										win_sum: '66.00'
									}
								],
								win_comb: '6,19,23,25,39,41 :4'
							}
						],
						drawing_date_begin: '2020-04-08 17:10:22',
						drawing_date_end: '2021-04-08 13:52:21',
						winning_date_end: '2021-04-08 13:52:21'
					},
					{
						draw_code: 4152,
						draw_id: 68823266,
						draw_name: '1842',
						drawing: [
							{
								extra_info: 'Мега джекпот наступн.тиражу - 30,000 грн.',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'за 6+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 6:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 5:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 4:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 3:',
										win_cnt: '1',
										win_sum: '31.00'
									},
									{
										name: '5+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3+1:',
										win_cnt: '1',
										win_sum: '62.00'
									}
								],
								win_comb: '1,6,18,32,33,40 :2'
							}
						],
						drawing_date_begin: '2020-04-08 13:55:41',
						drawing_date_end: '2021-04-07 14:25:39',
						winning_date_end: '2021-04-07 14:25:39'
					},
					{
						draw_code: 4151,
						draw_id: 68823262,
						draw_name: '1841',
						drawing: [
							{
								extra_info: 'Мега джекпот наступн.тиражу - 30,000 грн.',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'за 6+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 6:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 5:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 4:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 3:',
										win_cnt: '1',
										win_sum: '22.00'
									},
									{
										name: '5+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3+1:',
										win_cnt: '1',
										win_sum: '44.00'
									}
								],
								win_comb: '2,6,20,32,34,37 :5'
							}
						],
						drawing_date_begin: '2020-04-08 13:40:25',
						drawing_date_end: '2021-04-07 14:25:24',
						winning_date_end: '2021-04-07 14:25:24'
					},
					{
						draw_code: 4150,
						draw_id: 68823259,
						draw_name: '1840',
						drawing: [
							{
								extra_info: 'Мега джекпот наступн.тиражу - 90,000 грн.',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'за 6+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 6:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 5:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 4:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 3:',
										win_cnt: '1',
										win_sum: '33.00'
									},
									{
										name: '5+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4+1:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3+1:',
										win_cnt: '1',
										win_sum: '66.00'
									}
								],
								win_comb: '7,10,17,22,25,36 :9'
							}
						],
						drawing_date_begin: '2020-04-08 13:25:11',
						drawing_date_end: '2021-04-07 14:25:10',
						winning_date_end: '2021-04-07 14:25:10'
					}
				],
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				lott_code: '1',
				lott_name: 'МЕГАЛОТ',
				sid,
				time_stamp,
				user_id: 431517
			}
		},
		{
			request: {
				ACTION: 'GetDrawResults',
				LOTTERY: '71',
				MAX_DRAW_COUNT: '5',
				DRAWING_DATE_MAX: `${currDate} 23:59:58`
			},
			response: {
				client_trans_id: '407122248453',
				currency: '',
				draw_count: '905',
				drawing_date_max: `${currDate} 23:59:58`,
				drawing_date_min: '2000-01-01 00:00:00',
				drawing_result: [
					{
						draw_code: 4268,
						draw_id: 69041538,
						draw_name: '02377',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '530778'
							}
						],
						drawing_date_begin: '2020-05-24 14:25:00',
						drawing_date_end: '2020-11-20 16:00:00',
						winning_date_end: '2020-11-20 16:00:00'
					},
					{
						draw_code: 4266,
						draw_id: 69041096,
						draw_name: '02376',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '828471'
							}
						],
						drawing_date_begin: '2020-05-23 14:25:00',
						drawing_date_end: '2020-11-19 16:00:00',
						winning_date_end: '2020-11-19 16:00:00'
					},
					{
						draw_code: 4264,
						draw_id: 69040654,
						draw_name: '02375',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '857655'
							}
						],
						drawing_date_begin: '2020-05-22 14:25:00',
						drawing_date_end: '2020-11-18 16:00:00',
						winning_date_end: '2020-11-18 16:00:00'
					},
					{
						draw_code: 4262,
						draw_id: 69040212,
						draw_name: '02374',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '790879'
							}
						],
						drawing_date_begin: '2020-05-21 14:25:00',
						drawing_date_end: '2020-11-17 16:00:00',
						winning_date_end: '2020-11-17 16:00:00'
					},
					{
						draw_code: 4260,
						draw_id: 69039770,
						draw_name: '02373',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '760119'
							}
						],
						drawing_date_begin: '2020-05-20 14:25:00',
						drawing_date_end: '2020-11-16 16:00:00',
						winning_date_end: '2020-11-16 16:00:00'
					}
				],
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				lott_code: '71',
				lott_name: 'Тіп',
				sid,
				time_stamp,
				user_id: 431517
			}
		},
		{
			request: {
				ACTION: 'GetDrawResults',
				LOTTERY: '71',
				MAX_DRAW_COUNT: '5',
				DRAWING_DATE_MAX: `${currDate} 23:59:58`
			},
			response: {
				client_trans_id: '407122704134',
				currency: '',
				draw_count: '880',
				drawing_date_max: `${currDate} 23:59:58`,
				drawing_date_min: '2000-01-01 00:00:00',
				drawing_result: [
					{
						draw_code: 4257,
						draw_id: 69039326,
						draw_name: '02372',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '621375'
							}
						],
						drawing_date_begin: '2020-05-19 14:25:00',
						drawing_date_end: '2020-11-15 16:00:00',
						winning_date_end: '2020-11-15 16:00:00'
					},
					{
						draw_code: 4254,
						draw_id: 69038883,
						draw_name: '02371',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '190833'
							}
						],
						drawing_date_begin: '2020-05-18 14:25:00',
						drawing_date_end: '2020-11-14 16:00:00',
						winning_date_end: '2020-11-14 16:00:00'
					},
					{
						draw_code: 4252,
						draw_id: 69038441,
						draw_name: '02370',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '690889'
							}
						],
						drawing_date_begin: '2020-05-17 14:25:00',
						drawing_date_end: '2020-11-13 16:00:00',
						winning_date_end: '2020-11-13 16:00:00'
					},
					{
						draw_code: 4250,
						draw_id: 69037981,
						draw_name: '02369',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '267097'
							}
						],
						drawing_date_begin: '2020-05-16 14:25:00',
						drawing_date_end: '2020-11-12 16:00:00',
						winning_date_end: '2020-11-12 16:00:00'
					}
				],
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				lott_code: '71',
				lott_name: 'Тіп',
				sid,
				time_stamp,
				user_id: 431517
			}
		},
		{
			request: {
				ACTION: 'GetDrawResults',
				LOTTERY: '72',
				MAX_DRAW_COUNT: '5',
				DRAWING_DATE_MAX: '2020-05-24 23:59:58'
			},
			response: {
				client_trans_id: '407123205128',
				currency: '',
				draw_count: '900',
				drawing_date_max: '2020-05-24 23:59:58',
				drawing_date_min: '2000-01-01 00:00:00',
				drawing_result: [
					{
						draw_code: 4267,
						draw_id: 69041213,
						draw_name: '02369',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '427852'
							}
						],
						drawing_date_begin: '2020-05-23 19:25:00',
						drawing_date_end: '2020-11-19 21:00:00',
						winning_date_end: '2020-11-19 21:00:00'
					},
					{
						draw_code: 4265,
						draw_id: 69040771,
						draw_name: '02368',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '1',
										win_sum: '5.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '1',
										win_sum: '5.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '043323'
							}
						],
						drawing_date_begin: '2020-05-22 19:25:00',
						drawing_date_end: '2020-11-18 21:00:00',
						winning_date_end: '2020-11-18 21:00:00'
					},
					{
						draw_code: 4263,
						draw_id: 69040329,
						draw_name: '02367',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '621995'
							}
						],
						drawing_date_begin: '2020-05-21 19:25:00',
						drawing_date_end: '2020-11-17 21:00:00',
						winning_date_end: '2020-11-17 21:00:00'
					},
					{
						draw_code: 4261,
						draw_id: 69039887,
						draw_name: '02366',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '625199'
							}
						],
						drawing_date_begin: '2020-05-20 19:25:00',
						drawing_date_end: '2020-11-16 21:00:00',
						winning_date_end: '2020-11-16 21:00:00'
					},
					{
						draw_code: 4258,
						draw_id: 69039443,
						draw_name: '02365',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '968726'
							}
						],
						drawing_date_begin: '2020-05-19 19:25:00',
						drawing_date_end: '2020-11-15 21:00:00',
						winning_date_end: '2020-11-15 21:00:00'
					}
				],
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				lott_code: '72',
				lott_name: 'Топ',
				sid,
				time_stamp,
				user_id: 431517
			}
		},
		{
			request: {
				ACTION: 'GetDrawResults',
				LOTTERY: '72',
				MAX_DRAW_COUNT: '5',
				DRAWING_DATE_MAX: '2020-05-19 19:24:59'
			},
			response: {
				client_trans_id: '407123371529',
				currency: '',
				draw_count: '875',
				drawing_date_max: '2020-05-19 19:24:59',
				drawing_date_min: '2000-01-01 00:00:00',
				drawing_result: [
					{
						draw_code: 4255,
						draw_id: 69039000,
						draw_name: '02364',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '378635'
							}
						],
						drawing_date_begin: '2020-05-18 19:25:00',
						drawing_date_end: '2020-11-14 21:00:00',
						winning_date_end: '2020-11-14 21:00:00'
					},
					{
						draw_code: 4253,
						draw_id: 69038558,
						draw_name: '02363',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '916361'
							}
						],
						drawing_date_begin: '2020-05-17 19:25:00',
						drawing_date_end: '2020-11-13 21:00:00',
						winning_date_end: '2020-11-13 21:00:00'
					},
					{
						draw_code: 4251,
						draw_id: 69038098,
						draw_name: '02362',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '584786'
							}
						],
						drawing_date_begin: '2020-05-16 19:25:00',
						drawing_date_end: '2020-11-12 21:00:00',
						winning_date_end: '2020-11-12 21:00:00'
					},
					{
						draw_code: 4249,
						draw_id: 69037656,
						draw_name: '02361',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_cat: [
									{
										name: 'Усі 6 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Перші 5 цифр',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'Останні 5 цифр ',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '4 перші+остання',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '3 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 останні цифри',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '2 перші+1 останній',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 перша цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: '1 остання цифра',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+4 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+3 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'перша+2 останні',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 1+1 номер',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '931453'
							}
						],
						drawing_date_begin: '2020-05-15 19:25:00',
						drawing_date_end: '2020-11-11 21:00:00',
						winning_date_end: '2020-11-11 21:00:00'
					}
				],
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				lott_code: '72',
				lott_name: 'Топ',
				sid,
				time_stamp,
				user_id: 431517
			}
		},
		{
			request: {
				ACTION: 'GetDrawResults',
				LOTTERY: '13',
				MAX_DRAW_COUNT: '6',
				DRAWING_DATE_MAX: `${currDate} 23:59:58`
			},
			response: {
				client_trans_id: '407123710474',
				currency: '',
				draw_count: '68328',
				drawing_date_max: `${currDate} 23:59:58`,
				drawing_date_min: '2000-01-01 00:00:00',
				drawing_result: [
					{
						draw_code: 31387,
						draw_id: 69174246,
						draw_name: '240520/098',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_comb: '10,4,8,9'
							}
						],
						drawing_date_begin: '2020-05-24 16:26:20',
						drawing_date_end: '2099-11-20 16:30:10',
						winning_date_end: '2099-11-20 16:30:10'
					},
					{
						draw_code: 31386,
						draw_id: 69174163,
						draw_name: '240520/097',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_comb: '7,10,1,8'
							}
						],
						drawing_date_begin: '2020-05-24 16:21:10',
						drawing_date_end: '2099-11-20 16:25:00',
						winning_date_end: '2099-11-20 16:25:00'
					},
					{
						draw_code: 31385,
						draw_id: 69174080,
						draw_name: '240520/096',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_comb: '4,9,5,10'
							}
						],
						drawing_date_begin: '2020-05-24 16:16:00',
						drawing_date_end: '2099-11-20 16:19:50',
						winning_date_end: '2099-11-20 16:19:50'
					},
					{
						draw_code: 31384,
						draw_id: 69173997,
						draw_name: '240520/095',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_comb: '7,8,7,4'
							}
						],
						drawing_date_begin: '2020-05-24 16:10:50',
						drawing_date_end: '2099-11-20 16:14:40',
						winning_date_end: '2099-11-20 16:14:40'
					},
					{
						draw_code: 31383,
						draw_id: 69173914,
						draw_name: '240520/094',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_comb: '6,3,5,1'
							}
						],
						drawing_date_begin: '2020-05-24 16:05:40',
						drawing_date_end: '2099-11-20 16:09:30',
						winning_date_end: '2099-11-20 16:09:30'
					}
				],
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				lott_code: '13',
				lott_name: 'Гонка на гроші',
				sid,
				time_stamp,
				user_id: 431517
			}
		},
		{
			request: {
				ACTION: 'GetDrawResults',
				LOTTERY: '2',
				MAX_DRAW_COUNT: '6',
				DRAWING_DATE_MAX: `${currDate} 23:59:58`
			},
			response: {
				client_trans_id: '407124392972',
				currency: '',
				draw_count: '1440',
				drawing_date_max: `${currDate} 23:59:58`,
				drawing_date_min: '2000-01-01 00:00:00',
				drawing_result: [
					{
						draw_code: 4062,
						draw_id: 68404338,
						draw_name: '123',
						drawing: [
							{
								data: {
									events: [
										{
											eventDate: '2020-03-16 13:22:25',
											firstTeam: 't01',
											number: 1,
											result: {
												result: '1',
												score: '1:1'
											},
											secondTeam: 't13',
											t1: 80,
											t2: 5,
											tournament: 'Кубок Киевской области 2020',
											x: 15
										},
										{
											eventDate: '2020-03-16 13:22:25',
											firstTeam: 't02',
											number: 2,
											result: {
												result: '1',
												score: '1:1'
											},
											secondTeam: 't14',
											t1: 80,
											t2: 5,
											tournament: 'Кубок Киевской области 2020',
											x: 15
										},
										{
											eventDate: '2020-03-16 13:22:25',
											firstTeam: 't03',
											number: 3,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 't15',
											t1: 80,
											t2: 5,
											tournament: 'Кубок Киевской области 2020',
											x: 15
										},
										{
											eventDate: '2020-03-16 13:22:25',
											firstTeam: 't04',
											number: 4,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 't16',
											t1: 80,
											t2: 5,
											tournament: 'Кубок Киевской области 2020',
											x: 15
										},
										{
											eventDate: '2020-03-16 13:22:25',
											firstTeam: 't05',
											number: 5,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 't17',
											t1: 80,
											t2: 5,
											tournament: 'Кубок Киевской области 2020',
											x: 15
										},
										{
											eventDate: '2020-03-16 13:22:25',
											firstTeam: 't06',
											number: 6,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 't18',
											t1: 80,
											t2: 5,
											tournament: 'Кубок Киевской области 2020',
											x: 15
										},
										{
											eventDate: '2020-03-16 13:22:25',
											firstTeam: 't07',
											number: 7,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 't19',
											t1: 80,
											t2: 5,
											tournament: 'Кубок Киевской области 2020',
											x: 15
										},
										{
											eventDate: '2020-03-16 13:22:25',
											firstTeam: 't08',
											number: 8,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 't20',
											t1: 80,
											t2: 5,
											tournament: 'Кубок Киевской области 2020',
											x: 15
										},
										{
											eventDate: '2020-03-16 13:22:25',
											firstTeam: 't09',
											number: 9,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 't21',
											t1: 80,
											t2: 5,
											tournament: 'Кубок Киевской области 2020',
											x: 15
										},
										{
											eventDate: '2020-03-16 13:22:25',
											firstTeam: 't10',
											number: 10,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 't22',
											t1: 80,
											t2: 5,
											tournament: 'Кубок Киевской области 2020',
											x: 15
										},
										{
											eventDate: '2020-03-16 13:22:25',
											firstTeam: 't11',
											number: 11,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 't23',
											t1: 80,
											t2: 5,
											tournament: 'Кубок Киевской области 2020',
											x: 15
										},
										{
											eventDate: '2020-03-16 13:22:25',
											firstTeam: 't12',
											number: 12,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 't24',
											t1: 80,
											t2: 5,
											tournament: 'Кубок Киевской области 2020',
											x: 15
										}
									]
								},
								extra_info: 'Джекпот наступн.тиражу -  грн.',
								name: 'Основний розiграш',
								score: ' 1 (1:1),2 (1:1),3 (2:1),4 (2:1),5 (2:1),6 (2:1),7 (2:1),8 (2:1),9 (2:1),10 (2:1),11 (2:1),12 (2:1)',
								win_cat: [
									{
										name: 'за 12:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 11:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 10:',
										win_cnt: '4',
										win_sum: '3.00'
									}
								],
								win_comb: '0 0 1 1 1 1 1 1 1 1 1 1'
							}
						],
						drawing_date_begin: '2020-04-23 19:45:55',
						drawing_date_end: '2099-03-16 13:21:54',
						winning_date_end: '2099-03-16 13:21:54'
					},
					{
						draw_code: 4061,
						draw_id: 68403921,
						draw_name: '122',
						drawing: [
							{
								data: {
									events: [
										{
											eventDate: '2020-03-16 12:44:36',
											firstTeam: 'Троещина',
											number: 1,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 'Нивки',
											t1: 30,
											t2: 5,
											tournament: 'Кубок Киева',
											x: 65
										},
										{
											eventDate: '2020-03-16 12:44:36',
											firstTeam: 'Теремки',
											number: 2,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 'Дарница',
											t1: 30,
											t2: 5,
											tournament: 'Кубок Киева',
											x: 65
										},
										{
											eventDate: '2020-03-16 12:44:36',
											firstTeam: 'Печерск',
											number: 3,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 'Демеевка',
											t1: 30,
											t2: 5,
											tournament: 'Кубок Киева',
											x: 65
										},
										{
											eventDate: '2020-03-16 12:44:36',
											firstTeam: 'Подол',
											number: 4,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 'Минский',
											t1: 30,
											t2: 5,
											tournament: 'Кубок Киева',
											x: 65
										},
										{
											eventDate: '2020-03-16 12:44:36',
											firstTeam: 'Святошино',
											number: 5,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 'Голосеево',
											t1: 30,
											t2: 5,
											tournament: 'Кубок Киева',
											x: 65
										},
										{
											eventDate: '2020-03-16 12:44:36',
											firstTeam: 'Сырец',
											number: 6,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 'Ирпень',
											t1: 30,
											t2: 5,
											tournament: 'Кубок Киева',
											x: 65
										},
										{
											eventDate: '2020-03-16 12:44:36',
											firstTeam: 'Петровка',
											number: 7,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 'Старокиевский',
											t1: 30,
											t2: 5,
											tournament: 'Кубок Киева',
											x: 65
										},
										{
											eventDate: '2020-03-16 12:44:36',
											firstTeam: 'Борщаговка',
											number: 8,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 'Шулявка',
											t1: 30,
											t2: 5,
											tournament: 'Кубок Киева',
											x: 65
										},
										{
											eventDate: '2020-03-16 12:44:36',
											firstTeam: 'Виноградарь',
											number: 9,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 'Соломенка',
											t1: 30,
											t2: 5,
											tournament: 'Кубок Киева',
											x: 65
										},
										{
											eventDate: '2020-03-16 12:44:36',
											firstTeam: 'Вознесенка',
											number: 10,
											result: {
												result: '2',
												score: '2:1'
											},
											secondTeam: 'Жуляны',
											t1: 30,
											t2: 5,
											tournament: 'Кубок Киева',
											x: 65
										},
										{
											eventDate: '2020-03-16 12:44:36',
											firstTeam: 'Липки',
											number: 11,
											result: {
												result: '1',
												score: '2:2'
											},
											secondTeam: 'Академгородок',
											t1: 30,
											t2: 5,
											tournament: 'Кубок Киева',
											x: 65
										},
										{
											eventDate: '2020-03-16 12:44:36',
											firstTeam: 'Лукяновка',
											number: 12,
											result: {
												result: '4',
												score: '2:3'
											},
											secondTeam: 'Боярка',
											t1: 30,
											t2: 5,
											tournament: 'Кубок Киева',
											x: 65
										}
									]
								},
								extra_info: 'Джекпот наступн.тиражу - 250,000 грн.',
								name: 'Основний розiграш',
								score: ' 1 (2:1),2 (2:1),3 (2:1),4 (2:1),7 (2:1),8 (2:1),9 (2:1),10 (2:1),11 (2:2),5 (2:1),6 (2:1),12 (2:3)',
								win_cat: [
									{
										name: 'за 12:',
										win_cnt: '3',
										win_sum: '19.00'
									},
									{
										name: 'за 11:',
										win_cnt: '11',
										win_sum: '19.00'
									},
									{
										name: 'за 10:',
										win_cnt: '69',
										win_sum: '4.00'
									}
								],
								win_comb: '1 1 1 1 1 1 1 1 1 1 0 2'
							}
						],
						drawing_date_begin: '2020-04-09 13:30:01',
						drawing_date_end: '2099-03-17 13:10:00',
						winning_date_end: '2099-03-17 13:10:00'
					},
					{
						draw_code: 4053,
						draw_id: 68385920,
						draw_name: '120',
						drawing: [
							{
								data: {
									events: [
										{
											eventDate: '2020-03-13 15:11:24',
											firstTeam: 'e',
											number: 1,
											result: {
												result: '2',
												score: '3:1'
											},
											secondTeam: 'f',
											t1: 30,
											t2: 30,
											tournament: 'erewff',
											x: 40
										},
										{
											eventDate: '2020-03-13 15:11:24',
											firstTeam: 'e',
											number: 2,
											result: {
												result: '2',
												score: '3:1'
											},
											secondTeam: 'f',
											t1: 30,
											t2: 30,
											tournament: 'qwf',
											x: 40
										},
										{
											eventDate: '2020-03-13 15:11:24',
											firstTeam: 'e',
											number: 3,
											result: {
												result: '2',
												score: '3:1'
											},
											secondTeam: 'f',
											t1: 30,
											t2: 30,
											tournament: 'aef',
											x: 40
										},
										{
											eventDate: '2020-03-13 15:11:24',
											firstTeam: 'e',
											number: 4,
											result: {
												result: '2',
												score: '3:1'
											},
											secondTeam: 'f',
											t1: 30,
											t2: 30,
											tournament: 'waf',
											x: 40
										},
										{
											eventDate: '2020-03-13 15:11:24',
											firstTeam: 'e',
											number: 5,
											result: {
												result: '2',
												score: '3:1'
											},
											secondTeam: 'f',
											t1: 30,
											t2: 30,
											tournament: 'waf',
											x: 40
										},
										{
											eventDate: '2020-03-13 15:11:24',
											firstTeam: 'e',
											number: 6,
											result: {
												result: '2',
												score: '3:1'
											},
											secondTeam: 'f',
											t1: 30,
											t2: 30,
											tournament: 'waf',
											x: 40
										},
										{
											eventDate: '2020-03-13 15:11:24',
											firstTeam: 'e',
											number: 7,
											result: {
												result: '2',
												score: '3:1'
											},
											secondTeam: 'f',
											t1: 30,
											t2: 30,
											tournament: 'waefw',
											x: 40
										},
										{
											eventDate: '2020-03-13 15:11:24',
											firstTeam: 'ew',
											number: 8,
											result: {
												result: '2',
												score: '3:1'
											},
											secondTeam: 'f',
											t1: 30,
											t2: 30,
											tournament: 'waf',
											x: 40
										},
										{
											eventDate: '2020-03-13 15:11:24',
											firstTeam: 'we',
											number: 9,
											result: {
												result: '2',
												score: '3:1'
											},
											secondTeam: 'f',
											t1: 30,
											t2: 30,
											tournament: 'wef',
											x: 40
										},
										{
											eventDate: '2020-03-13 15:11:24',
											firstTeam: 'ew',
											number: 10,
											result: {
												result: '2',
												score: '3:1'
											},
											secondTeam: 'f',
											t1: 30,
											t2: 30,
											tournament: 'wef',
											x: 40
										},
										{
											eventDate: '2020-03-13 15:11:24',
											firstTeam: 'eew',
											number: 11,
											result: {
												result: '2',
												score: '3:1'
											},
											secondTeam: 'f',
											t1: 30,
											t2: 30,
											tournament: 'wef',
											x: 40
										},
										{
											eventDate: '2020-03-13 15:11:24',
											firstTeam: 'ewfwe',
											number: 12,
											result: {
												result: '2',
												score: '3:1'
											},
											secondTeam: 'f',
											t1: 30,
											t2: 30,
											tournament: 'waef',
											x: 40
										}
									]
								},
								extra_info: 'Джекпот наступн.тиражу - 700,000 грн.',
								name: 'Основний розiграш',
								score: ' 8 (3:1),9 (3:1),1 (3:1),2 (3:1),3 (3:1),4 (3:1),5 (3:1),6 (3:1),7 (3:1),10 (3:1),11 (3:1),12 (3:1)',
								win_cat: [
									{
										name: 'за 12:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 11:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 10:',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '1 1 1 1 1 1 1 1 1 1 1 1'
							}
						],
						drawing_date_begin: '2020-03-13 15:15:11',
						drawing_date_end: '2099-03-13 15:11:18',
						winning_date_end: '2099-03-13 15:11:18'
					},
					{
						draw_code: 4051,
						draw_id: 68385875,
						draw_name: '119',
						drawing: [
							{
								data: {
									events: [
										{
											eventDate: '2020-03-13 13:19:35',
											firstTeam: 'First Command',
											number: 1,
											result: {
												result: '2',
												score: '1:0'
											},
											secondTeam: 'Second Command',
											t1: 30,
											t2: 30,
											tournament: 'Tournament',
											x: 40
										},
										{
											eventDate: '2020-03-13 13:19:35',
											firstTeam: 'First Command',
											number: 2,
											result: {
												result: '2',
												score: '1:0'
											},
											secondTeam: 'Second Command',
											t1: 30,
											t2: 30,
											tournament: 'Tournament',
											x: 40
										},
										{
											eventDate: '2020-03-13 13:19:35',
											firstTeam: 'First Command',
											number: 3,
											result: {
												result: '2',
												score: '1:0'
											},
											secondTeam: 'Second Command',
											t1: 30,
											t2: 30,
											tournament: 'Tournament',
											x: 40
										},
										{
											eventDate: '2020-03-13 13:19:35',
											firstTeam: 'First Command',
											number: 4,
											result: {
												result: '2',
												score: '1:0'
											},
											secondTeam: 'Second Command',
											t1: 30,
											t2: 30,
											tournament: 'Tournament',
											x: 40
										},
										{
											eventDate: '2020-03-13 13:19:35',
											firstTeam: 'First Command',
											number: 5,
											result: {
												result: '2',
												score: '1:0'
											},
											secondTeam: 'Second Command',
											t1: 30,
											t2: 30,
											tournament: 'Tournament',
											x: 40
										},
										{
											eventDate: '2020-03-13 13:19:35',
											firstTeam: 'First Command',
											number: 6,
											result: {
												result: '2',
												score: '1:0'
											},
											secondTeam: 'Second Command',
											t1: 30,
											t2: 30,
											tournament: 'Tournament',
											x: 40
										},
										{
											eventDate: '2020-03-13 13:19:35',
											firstTeam: 'First Command',
											number: 7,
											result: {
												result: '2',
												score: '1:0'
											},
											secondTeam: 'Second Command',
											t1: 30,
											t2: 30,
											tournament: 'Tournament',
											x: 40
										},
										{
											eventDate: '2020-03-13 13:19:35',
											firstTeam: 'First Command',
											number: 8,
											result: {
												result: '2',
												score: '1:0'
											},
											secondTeam: 'Second Command',
											t1: 30,
											t2: 30,
											tournament: 'Tournament',
											x: 40
										},
										{
											eventDate: '2020-03-13 13:19:35',
											firstTeam: 'First Command',
											number: 9,
											result: {
												result: '2',
												score: '1:0'
											},
											secondTeam: 'Second Command',
											t1: 30,
											t2: 30,
											tournament: 'Tournament',
											x: 40
										},
										{
											eventDate: '2020-03-13 13:19:35',
											firstTeam: 'First Command',
											number: 10,
											result: {
												result: '2',
												score: '1:0'
											},
											secondTeam: 'Second Command',
											t1: 30,
											t2: 30,
											tournament: 'Tournament',
											x: 40
										},
										{
											eventDate: '2020-03-13 13:19:35',
											firstTeam: 'First Command',
											number: 11,
											result: {
												result: '2',
												score: '1:0'
											},
											secondTeam: 'Second Command',
											t1: 30,
											t2: 30,
											tournament: 'Tournament',
											x: 40
										},
										{
											eventDate: '2020-03-13 13:19:35',
											firstTeam: 'First Command',
											number: 12,
											result: {
												result: '2',
												score: '1:0'
											},
											secondTeam: 'Second Command',
											t1: 30,
											t2: 30,
											tournament: 'Tournament',
											x: 40
										}
									]
								},
								extra_info: 'Джекпот наступн.тиражу -  грн.',
								name: 'Основний розiграш',
								score: ' 11 (1:0),12 (1:0),1 (1:0),2 (1:0),3 (1:0),4 (1:0),5 (1:0),6 (1:0),7 (1:0),8 (1:0),9 (1:0),10 (1:0)',
								win_cat: [
									{
										name: 'за 12:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 11:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 10:',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '1 1 1 1 1 1 1 1 1 1 1 1'
							}
						],
						drawing_date_begin: '2020-03-13 13:18:51',
						drawing_date_end: '2099-03-13 13:18:59',
						winning_date_end: '2099-03-13 13:18:59'
					},
					{
						draw_code: 4049,
						draw_id: 68385515,
						draw_name: '118',
						drawing: [
							{
								data: {
									events: [
										{
											eventDate: '2020-03-12 17:40:41',
											firstTeam: 'Game',
											number: 1,
											result: {
												result: '1',
												score: '1:1'
											},
											secondTeam: 'Game',
											t1: 30,
											t2: 30,
											tournament: 'Game',
											x: 40
										},
										{
											eventDate: '2020-03-12 17:40:41',
											firstTeam: 'Game',
											number: 2,
											result: {
												result: '1',
												score: '1:1'
											},
											secondTeam: 'Game',
											t1: 30,
											t2: 30,
											tournament: 'Game',
											x: 40
										},
										{
											eventDate: '2020-03-12 17:40:41',
											firstTeam: 'Game',
											number: 3,
											result: {
												result: '1',
												score: '1:1'
											},
											secondTeam: 'Game',
											t1: 30,
											t2: 30,
											tournament: 'Game',
											x: 40
										},
										{
											eventDate: '2020-03-12 17:40:41',
											firstTeam: 'Game',
											number: 4,
											result: {
												result: '1',
												score: '1:1'
											},
											secondTeam: 'Game',
											t1: 30,
											t2: 30,
											tournament: 'Game',
											x: 40
										},
										{
											eventDate: '2020-03-12 17:40:41',
											firstTeam: 'Game',
											number: 5,
											result: {
												result: '1',
												score: '1:1'
											},
											secondTeam: 'Game',
											t1: 30,
											t2: 30,
											tournament: 'Game',
											x: 40
										},
										{
											eventDate: '2020-03-12 17:40:41',
											firstTeam: 'Game',
											number: 6,
											result: {
												result: '1',
												score: '1:1'
											},
											secondTeam: 'Game',
											t1: 30,
											t2: 30,
											tournament: 'Game',
											x: 40
										},
										{
											eventDate: '2020-03-12 17:40:41',
											firstTeam: 'Game',
											number: 7,
											result: {
												result: '1',
												score: '1:1'
											},
											secondTeam: 'Game',
											t1: 30,
											t2: 30,
											tournament: 'Game',
											x: 40
										},
										{
											eventDate: '2020-03-12 17:40:41',
											firstTeam: 'Game',
											number: 8,
											result: {
												result: '1',
												score: '1:1'
											},
											secondTeam: 'Game',
											t1: 30,
											t2: 30,
											tournament: 'Game',
											x: 40
										},
										{
											eventDate: '2020-03-12 17:40:41',
											firstTeam: 'Game',
											number: 9,
											result: {
												result: '1',
												score: '1:1'
											},
											secondTeam: 'Game',
											t1: 30,
											t2: 30,
											tournament: 'Game',
											x: 40
										},
										{
											eventDate: '2020-03-12 17:40:41',
											firstTeam: 'Game',
											number: 10,
											result: {
												result: '1',
												score: '1:1'
											},
											secondTeam: 'Game',
											t1: 30,
											t2: 30,
											tournament: 'Game',
											x: 40
										},
										{
											eventDate: '2020-03-12 17:40:41',
											firstTeam: 'Game',
											number: 11,
											result: {
												result: '1',
												score: '1:1'
											},
											secondTeam: 'Game',
											t1: 30,
											t2: 30,
											tournament: 'Game',
											x: 40
										},
										{
											eventDate: '2020-03-12 17:40:41',
											firstTeam: 'Game',
											number: 12,
											result: {
												result: '1',
												score: '1:1'
											},
											secondTeam: 'Game',
											t1: 30,
											t2: 30,
											tournament: 'Game',
											x: 40
										}
									]
								},
								extra_info: 'Джекпот наступн.тиражу -  грн.',
								name: 'Основний розiграш',
								score: ' 1 (1:1),2 (1:1),3 (1:1),5 (1:1),6 (1:1),7 (1:1),8 (1:1),9 (1:1),10 (1:1),11 (1:1),12 (1:1),4 (1:1)',
								win_cat: [
									{
										name: 'за 12:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 11:',
										win_cnt: '0',
										win_sum: '0.00'
									},
									{
										name: 'за 10:',
										win_cnt: '0',
										win_sum: '0.00'
									}
								],
								win_comb: '0 0 0 0 0 0 0 0 0 0 0 0'
							}
						],
						drawing_date_begin: '2020-03-13 12:48:21',
						drawing_date_end: '2099-04-15 14:48:30',
						winning_date_end: '2099-04-15 14:48:30'
					}
				],
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				lott_code: '2',
				lott_name: 'Спортпрогноз',
				sid,
				time_stamp,
				user_id: 431517
			}
		},
		{
			request: {
				ACTION: 'GetDrawResults',
				LOTTERY: '52',
				MAX_DRAW_COUNT: '6',
				DRAWING_DATE_MAX: `${currDate} 23:59:58`
			},
			response: {
				client_trans_id: '407124890381',
				currency: '',
				draw_count: '42024',
				drawing_date_max: `${currDate} 23:59:58`,
				drawing_date_min: '2000-01-01 00:00:00',
				drawing_result: [
					{
						draw_code: 94774,
						draw_id: 69042561,
						draw_name: '180520/259',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_comb: '7Б,10Б,ТБ,4Т,9Т\n'
							}
						],
						drawing_date_begin: '2020-05-18 22:20:16',
						drawing_date_end: '2020-06-18 22:20:55',
						winning_date_end: '2020-06-18 22:20:55'
					},
					{
						draw_code: 94773,
						draw_id: 69042559,
						draw_name: '180520/258',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_comb: 'ТП,9Ч,КБ,2Т,3Т\n'
							}
						],
						drawing_date_begin: '2020-05-18 22:15:06',
						drawing_date_end: '2020-06-18 22:15:45',
						winning_date_end: '2020-06-18 22:15:45'
					},
					{
						draw_code: 94772,
						draw_id: 69042557,
						draw_name: '180520/257',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_comb: '6Ч,КТ,КП,3Б,9Т\nПара'
							}
						],
						drawing_date_begin: '2020-05-18 22:09:56',
						drawing_date_end: '2020-06-18 22:10:35',
						winning_date_end: '2020-06-18 22:10:35'
					},
					{
						draw_code: 94771,
						draw_id: 69042555,
						draw_name: '180520/256',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_comb: 'ВТ,3П,9Б,ВБ,8Ч\nПара'
							}
						],
						drawing_date_begin: '2020-05-18 22:04:46',
						drawing_date_end: '2020-06-18 22:05:25',
						winning_date_end: '2020-06-18 22:05:25'
					},
					{
						draw_code: 94770,
						draw_id: 69042553,
						draw_name: '180520/255',
						drawing: [
							{
								extra_info: '',
								name: 'Основний розiграш',
								score: '',
								win_comb: 'ВТ,КЧ,ДП,3Б,3Ч\nПара'
							}
						],
						drawing_date_begin: '2020-05-18 21:59:36',
						drawing_date_end: '2020-06-18 22:00:15',
						winning_date_end: '2020-06-18 22:00:15'
					}
				],
				err_code: 0,
				err_descr: 'Операція виконана успішно.',
				lang: 'ua',
				lott_code: '52',
				lott_name: 'Каре',
				sid,
				time_stamp,
				user_id: 431517
			}
		},
		{
			request: {
				ACTION: 'BonusPay',
				BARCODE: '0729003245090860'
			},
			response: {
				client_id: '12345',
				client_trans_id: '12345',
				err_code: 0,
				ticket: []
			}
		},
		{
			request: {
				ACTION: 'BonusPay',
				BARCODE: '0729003246040976'
			},
			response: {
				client_id: '12345',
				client_trans_id: '12345',
				err_code: 1234,
				err_descr: 'Упс! ОшибочкО вышло...'
			}
		},
		{
			request: {
				ACTION: 'CheckCashOut'
			},
			response: {
				client_trans_id: '12345',
				err_code: 0,
				err_descr: '',
				pay_sum: 1000,
				pay_expire_date: (new Date(Date.now() + 60000)).toISOString(),
				pay_enable: 1,
				reason_code: 123,
				reason_detail: 'Test',
				pay_reg_date: (new Date(Date.now() - 60000)).toISOString(),
				pay_reg_terminal: 123
			}
		},
		{
			request: {
				ACTION: 'GetPayedTicketInfo'
			},
			response: {
				client_trans_id: '12345',
				err_code: 0,
				err_descr: '',
				is_win_pay_allowed: true
			}
		},
	],
	PromoSrv: [
		{
			request: {
				ACTION: 'GetBonusBalance'
			},
			response: {
				user_id: '12345',
				card_num: '12345678',
				bonus_balance: 500
			}
		},
		{
			request: {
				ACTION: 'SendPinCode'
			},
			response: {
				client_id: '12345'
			}
		},
		{
			request: {
				ACTION: 'VerifyPhone'
			},
			response: {
				client_id: '12345'
			}
		},
		{
			request: {
				ACTION: 'AssignCard'
			},
			response: {
				phone: '+380681234567',
				user_id: '12345',
				card_num: '1234567',
				err_code: '0',
				err_descr: ''
			}
		},
		{
			request: {
				ACTION: 'GetCardUser'
			},
			response: {
				card_num: '11111111',
				user_id: 1,
				phone: '+380681234567',
				err_code: 0,
				err_descr: '',
				client_trans_id: '111'
			}
		},
		{
			request: {
				ACTION: 'GetCardInfo'
			},
			response: {
				client_trans_id: '111',
				"err_code": 0,
				"err_descr": "Операція успішна",
				"total_bonus_sum": 640,
				"card_limits": {
					"max_bonus_use_limit": {
						"current_value": 0,
						"limit_value": 160000
					},
					"withdraw_limit": {
						"current_value": 0,
						"limit_value": 150000
					},
					"max_winpay_limit": {
						"current_value": 0,
						"limit_value": 100000
					},
					"min_bonus_sum_with_auth": {
						"limit_value": 5000
					},
					"verification_needed_min_win_sum": {
						"limit_value": 50000
					}
				},
				"challenges": [
					{
						"id": 4,
						"code": 4,
						"name": "Купити білети Лото-Забава",
						"action_type": "ACTION",
						"date_begin": "2024-03-25T00:00:00+02:00",
						"date_end": "2024-03-31T00:00:00+02:00",
						"amount_total": 0,
						"count_total": 0,
						"bonus_type_code": "PERCENT_OF_AMOUNT",
						"bonus_value": 15,
						"action_condition": "Купити білети Лото-Забава в період від 25.03.2024 по 31.03.2024 і отримати бонус, що дорівнює вартості всіх куплених білетів * 2",
						"participating_lotteries": [
							{
								"id": 169954,
								"code": 3,
								"name": "Лото-Забава",
								"type": "I",
								"is_accrual_bonuses": true,
								"is_use_bonuses": false
							}
						]
					}
				]
			}
		},
		{
			request: {
				ACTION: 'GetAvailableBonus'
			},
			response: {
				available_bonus_sum: 1000,
				available_bonus_sum_money: 1000,
				bet_sum_bonuses: 2000,
				used_bonuses_bonuses: 500
			}
		},
		{
			request: {
				ACTION: 'GetWithdrawBonus'
			},
			response: {
				/**
				 *  Минимальное количество бонусов, которое можно вывести с карты.
				 */
				min_available_bonus_sum: 100,

				/**
				 * Денежный эквивалент минимального количества бонусов, которое можно вывести с карты
				 */
				min_available_bonus_sum_money: 100,

				/**
				 *  Максимальное количество бонусов, которое можно вывести с карты.
				 */
				max_available_bonus_sum: 500,

				/**
				 * Денежный эквивалент максимального количества бонусов, которое можно вывести с карты.
				 */
				max_available_bonus_sum_money: 500,

				err_code: 0,
				err_descr: '',
				client_trans_id: '12345'
			}
		},
		{
			request: {
				ACTION: 'WithdrawBonus'
			},
			response: {
				err_code: 0,
				err_descr: '',
				client_trans_id: '12345'
			}
		},
		{
			request: {
				ACTION: 'WinPayToCard'
			},
			response: {
				err_code: 0,
				err_descr: '',
				client_trans_id: '12345'
			}
		}
	]
};

/**
 * Класс для работы с mock-запросами
 */
export class Mocks {

	/**
	 * Конструктор класса
	 * @param url Адрес запроса
	 * @param body Тело запроса
	 * @param options Опции запроса
	 */
	constructor(private readonly url: string, private readonly body: string, private readonly options: any) {
	}

	/**
	 * Функция отправки запроса
	 */
	sendRequest(): PromiseLike<IResponse> {
		const paramsArray = this.body.split('&');
		const paramsObject = {};
		for (const paramItem of paramsArray) {
			const tmp = paramItem.split('=');
			paramsObject[tmp[0]] = tmp[1];
		}
		let matches = true;
		console.log('paramsObject =', paramsObject);
		for (const endpoint of Object.keys(MOCK_DATA)) {
			if (this.url.endsWith(endpoint)) {
				const mockItems = MOCK_DATA[endpoint];
				for (const mockItem of mockItems) {
					matches = true;
					for (const param of Object.keys(mockItem.request)) {
						if (mockItem.request[param] !== paramsObject[param]) {
							matches = false;
							break;
						}
					}
					if (matches) {
						return mockItem.response;
					}
				}

				return {
					client_trans_id: '12345678',
					err_code: 65535,
					err_descr: 'Операцию можно выполнить только на реальном бек-енде'
				} as any;
			}
		}
	}
}
