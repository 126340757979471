import { Component, OnInit } from '@angular/core';
import { ChangeLogService } from '../../services/change-log.service';
import {AppStoreService} from "@app/core/services/store/app-store.service";

/**
 * Компонент, предоставляющий функционал для отображения списка изменений.
 */
@Component({
	selector: 'app-change-log',
	templateUrl: './change-log.component.html',
	styleUrls: ['./change-log.component.scss']
})
export class ChangeLogComponent implements OnInit {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Ссылка на сервис.
	 */
	changeLogService: ChangeLogService;

	/**
	 * Признак показа только последней версии изменений.
	 */
	isOnlyLatestChanges = false;

	/**
	 * Режим генерации билета для печати.
	 */
	trsMode = 0;

	constructor(
		readonly appStoreService: AppStoreService
	) {
	}

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Обработчик нажатия на кнопку "Закрыть окно".
	 * @param overlay Признак клика на оверлее.
	 */
	onClickCloseHandler(overlay = false): void {
		// скрываем диалог во всех случаях
		// кроме клика на оверлее первого большого ченджлога
		if (!(overlay && this.isOnlyLatestChanges)) {
			this.changeLogService.hideDialog();
		}
	}

	/**
	 * Обработчик изменения режима генерации билета для печати.
	 * @param event Передаваемое событие.
	 */
	onTRSModeChange(event: Event): void {
		this.trsMode = +(event.target as HTMLInputElement).value;
		localStorage.setItem('TRS_MODE', this.trsMode.toString());
	}

	onShowCameraChange(): void {
		this.appStoreService.showCameraComponent$$.next(!this.appStoreService.showCameraComponent$$.value);
		localStorage.setItem('SHOW_CAMERA', this.appStoreService.showCameraComponent$$.value ? '1' : '0');
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		this.trsMode = +localStorage.getItem('TRS_MODE') || 0;
		this.appStoreService.showCameraComponent$$.next(localStorage.getItem('SHOW_CAMERA') === '1');
	}
}

