import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from '@app/core/net/http/services/http.service';
import { LotteriesDraws } from '@app/core/services/store/draws';
import { Menu } from '@app/core/services/store/menu';
import { Operator } from '@app/core/services/store/operator';
import { Settings } from '@app/core/services/store/settings';
import {EsapParams} from "@app/core/configuration/esap";
import {ITransactionTicket} from "@app/core/services/transaction/transaction-types";

/**
 * Интерфейс для хранения информации об игроке.
 */
export interface PlayerInfo {
	/**
	 * Телефон игрока.
	 */
	playerPhone: string;
	/**
	 * Код авторизации игрока.
	 */
	playerAuthCode: string;
}

/**
 * Сервис для централизованного хранения разнородной информации (хранилище приложения).
 */
@Injectable({
	providedIn: 'root'
})
export class AppStoreService {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Содержит значение, указывающее на то прошел ли авторизацию терминал и оператор (true) или нет (false).
	 */
	readonly isLoggedIn$$ = new BehaviorSubject<boolean>(false);

	/**
	 * Объект меню.
	 */
	readonly menu: Menu = new Menu();

	/**
	 * Объект оператора.
	 */
	readonly operator = new BehaviorSubject<Operator>(undefined);

	/**
	 * Массив сообщений для вывода в консоль.
	 */
	readonly consoleArray: Array<{text: string; type?: string}> = [];

	showBrowserPrint = false;

	tickets: Array<ITransactionTicket> = [];

	printGameName = '';

	winPaid = 0;

	/**
	 * Геттер для получения настроек приложения.
	 */
	get Settings(): Settings {
		return this._settings;
	}

	/**
	 * Геттер лотерей и тиражей по данным лотереям.
	 */
	get Draws(): LotteriesDraws | undefined {
		return this._draws;
	}
	/**
	 * Сеттер лотерей и тиражей по данным лотереям.
	 * @param {LotteriesDraws} value Объект лотерей и тиражей.
	 */
	set Draws(value: LotteriesDraws) {
		this._draws = value;
	}

	/**
	 * Геттер для получения информации об игроке.
	 */
	get playerInfo(): PlayerInfo {
		return this._playerInfo;
	}

	/**
	 * Сеттер для установки информации об игроке.
	 * @param pli Объект с информацией об игроке.
	 */
	set playerInfo(pli: PlayerInfo) {
		this._playerInfo = pli;
	}

	/**
	 * Была ли допущена ошибка ввода кода из СМС ?
	 */
	smsCodeError = false;

	/**
	 * Была ли нажата кнопка "Назад" ?
	 */
	backClicked = false;

	/**
	 * Текущий URL
	 */
	currentURL = '';

	/**
	 * Предыдущий URL
	 */
	previousURL = '';

	/**
	 * Показана ли клавиатура мобильного устройства?
	 */
	mobileKeyboardShown = false;

	/**
	 * Номер карты при продаже лотереи
	 */
	cardNum = '';

	/**
	 * Количество бонусов при оплате ставки (в копейках)
	 */
	bonusPaySum = 0;

	/**
	 * Ошибка доступа к камере
	 */
	scannerError = '';

	showCameraComponent$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(localStorage.getItem('SHOW_CAMERA') === '1');

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Объект настроек приложения.
	 * @private
	 */
	private readonly _settings: Settings;
	/**
	 * Объект лотерей и тиражей.
	 * @private
	 */
	private _draws: LotteriesDraws;
	/**
	 * Объект с информацией об игроке.
	 * @private
	 */
	private _playerInfo: PlayerInfo = null;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {HttpService} httpService Сервис для работы с HTTP-запросами.
	 */
	constructor(
		private readonly httpService: HttpService
	) {
		this._settings = new Settings();
		httpService.settings = this.Settings;
	}

	/**
	 * Инициализирован ли контейнер оператора.
	 *
	 * @returns {boolean}
	 */
	hasOperator(): boolean {
		return !!this.operator.getValue();
	}

}
